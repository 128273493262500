export function getQuestions (state) {
  return state.questions
}

export function getQuestionnaireType (state) {
  return state.questionnaireType
}

export function getPageNumber (state) {
  return state.page
}

export function isAuthenticated (state) {
  return state.currentUser.loggedIn && state.currentUser.data.emailVerified
}

export function isAnonym (state) {
  return state.currentUser.loggedIn && state.currentUser.data.isAnonym
}

export function getUserData (state) {
  return state.currentUser.data
}

export function getInterviewee (state) {
  return state.interviewee
}

export function getEmail (state) {
  return state.email
}

export function getPhone (state) {
  return state.phone
}

export function getViewHistory (state) {
  return state.currentUser.history
}

export function getTotalResearches (state) {
  return state.currentUser.history.totalResearches
}
