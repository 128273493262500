import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  theme: {
    background: '#3A1C71',
    primary: '#C52C34',
    secondary: '#26A69A',
    accent: '#C52C34',
    error: '#DB2828'
  },
  iconfont: 'md'
})
