const util = {
  path: {
    research: {
      FORGOT_PASSWORD: '/password-reset',
      CODE_LOGIN: '/research/code/',
      CODE_LOGIN_DIRECT: '/research/code/:code',
      RESEARCH_QUESTIONNAIRE: '/research/questionnaire',
      FORM: '/research/form',
      USER_RESEARCHES: '/research/user/researches',
      FIRST_QUESTION_ADULT: '/research/questionnaire/adult/1',
      FIRST_QUESTION_ADOLESCENT: '/research/questionnaire/adolescent/1',
      QUESTION_ADULT: '/research/questionnaire/adult/:num',
      QUESTION_ADOLESCENT: '/research/questionnaire/adolescent/:num',
      SCORE: '/research/score',
      THANK_YOU: '/research/thanks',
      TAB_HISTORY: '/research/tab/history',
      TAB_QUESTIONNAIRE: '/research/tab/questionnaire',
      TAB_CODES: '/research/tab/codes',
      TAB_SETTINGS: '/research/tab/settings',
      TERMS_AND_CONDITION: '/research/terms'
    },
    RESEARCH: '/research',
    SIGNUP: '/join',
    LOGIN: '/login',
    HOME: '/',
    QUESTIONNAIRE: '/questionnaire',
    QUESTION_ADULT: '/questionnaire/adult/:num',
    QUESTION_ADOLESCENT: '/questionnaire/adolescent/:num',
    FIRST_QUESTION_ADULT: '/questionnaire/adult/1',
    FIRST_QUESTION_ADOLESCENT: '/questionnaire/adolescent/1',
    SCORE: '/questionnaire/score',
    NOT_FOUND: '*'
  }
}

export default util
