export default {
  questions: [],
  questionnaireType: '',
  page: 1,
  currentUser: {
    loggedIn: false,
    data: {
      code: {
        fields: {}
      }
    },
    history: {
      totalResearches: 0,
      researches: {}
    }
  },
  email: undefined,
  interviewee: {
    identificationCode: '',
    nameInitials: '',
    age: '',
    gender: '',
    civilStatus: '',
    skinColor: '',
    habitation: '',
    job: '',
    schooling: '',
    height: '',
    weight: '',
    imc: '',
    sbp: '',
    dbp: '',
    referredDiseases: '',
    referredReligion: '',
    howManyYearsAgo: '',
    physicalHealth: '',
    mentalHealth: '',
    lifeQuality: '',
    whatToImprove: ''
  }
}
