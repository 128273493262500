export default {
  saudacao: 'Hello',
  home: {
    toolbar: {
      inicio: 'ابدأ',
      saberMais: 'اعرف المزيد',
      pesquisas: 'البحوث',
      arquivos: 'الملفات',
      login: 'دخول'
    },
    section: {
      questionario: {
        titulo: 'استبيان ثمانية علاجات طبيعية',
        texto: 'أجب على الاستبيان وتحقق من جودة حياتك!'
      },
      saberMais: {
        titulo: '8 علاجات طبيعية',
        texto: 'هل تريد معرفة المزيد عن العلاجات الطبيعية الثمانية؟',
        botao: 'أريد معرفة المزيد!'
      },
      pesquisas: {
        titulo: 'الأبحاث',
        texto: 'لدينا منصتنا الخاصة لإجراء البحوث.',
        botao: 'أريد المشاركة في البحث',
        tenhoUmCodigo: 'لدي رمز وصول'
      },
      arquivos: {
        titulo: 'الملفات',
        texto: 'نسخ قابلة للطباعة من الاستبيانات'
      }
    },
    footer: {
      grupo: 'مجموعة البحث" التدين والروحانية في الصحة الشاملة "(مجموعة REIS)',
      programa: 'برنامج الماجستير المهني في تعزيز الصحة',
      unasp: 'مركز جامعة السبتية في ساو باولو - UNASP / SP',
      contato: '2128 6143'
    }
  },
  login: {
    titulo: 'تسجيل الدخول',
    email: 'البريد الإلكتروني',
    password: 'كلمة المرور',
    entrar: 'تسجيل الدخول',
    logout: 'تسجيل الخروج',
    naoPossuoConta: 'ليس لدي حساب بعد',
    emailVerificacao: {
      titulo: 'تم إرسال رسالة التحقق!',
      mensagem: 'الرجاء إرسال التأكيد إلى بريدك الإلكتروني.'
    },
    esqueciMinhaSenha: 'لقد نسيت كلمة المرور'
  },
  esqueciMinhaSenha: {
    titulo: 'لقد نسيت كلمة المرور الخاصة بي',
    email: 'البريد الإلكتروني',
    botao: 'إرسال',
    emailEnviado: 'تم إرسال بريد الاسترداد الإلكتروني',
    emailNaoCadastrado: 'البريد الإلكتروني غير مسجل',
    erroEnviarEmail: 'خطأ في إرسال البريد الإلكتروني'
  },
  codeLogin: {
    titulo: 'أدخل بالرمز',
    email: 'البريد الإلكتروني',
    code: 'كود',
    botao: 'شارك',
    codigoInvalido: 'رمز غير صالح.'
  },
  cadastro: {
    titulo: 'تسجيل',
    cadastrar: 'التسجيل',
    nome: 'الاسم',
    Instituicao: 'مؤسسة'
  },
  validacao: {
    Invalido: 'اسم مستخدم أو كلمة مرور غير صالحة',
    emailJaUtilizado: 'البريد الإلكتروني قيد الاستخدام بالفعل',
    campoObrigatorio: 'حقل مطلوب',
    emailValido: 'يجب أن يكون البريد الإلكتروني صالحًا',
    senhaFraca: 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل'
  },
  erroInesperado: 'خطأ غير متوقع',
  snackbar: {
    close: 'إغلاق'
  },
  tab: {
    historico: {
      titulo: 'البحوث',
      questionariosRecentes: 'الاستبيانات الأخيرة',
      questionariosAnteriores: 'الاستبيانات السابقة',
      escore: 'نقاط',
      naoExistemQuestionarios: 'لا توجد استبيانات مكتملة',
      Instituicao: 'المؤسسة',
      minhasPesquisas: 'أبحاثي',
      usuarios: 'المستخدمون',
      pesquisas: 'أبحاث'
    },
    questionario: {
      titulo: 'استبيان',
      adulto: 'بالغ',
      adolescente: 'مراهق',
      questionarioSemFormulario: 'استبيان بدون نموذج',
      realizarQuestionario: 'إجراء الاستبيان'
    },
    codigos: {
      titulo: 'الرموز',
      meusCodigos: 'أكوادي',
      form: {
        codigo: 'كود',
        descricao: 'وصف شفرتك'
      },
      criar: 'إنشاء',
      إلغاء: 'إلغاء',
      codigoExistente: 'الرمز موجود بالفعل',
      naoExistemCodigos: 'لا توجد رموز مسجلة'
    },
    configuracoes: {
      titulo: 'الإعدادات',
      rede: 'الشبكة',
      sincronizacao: 'سينك',
      ultimaSincronizacao: 'آخر مزامنة',
      idioma: 'اللغة',
      escolhaIdioma: 'اختر لغة التطبيق',
      pickLanguage: 'اختر لغة',
      ajuda: 'مساعدة',
      relatarProblema: 'الإبلاغ عن مشكلة ،',
      problemaAplicacao: 'هناك مشكلة في التطبيق',
      exportar: 'تصدير البيانات',
      ExportarDados: 'تصدير جميع الأبحاث التي تم إجراؤها إلى ملف CSV',
      listaVazia: 'لا توجد بيانات ليتم تصديرها'
    }
  },
  dadosEntrevistado: {
    titulo: 'بيانات المقابلة'
  },
  formulario: {
    titulo: 'ملء النموذج',
    campoObrigatorio: 'حقل مطلوب',
    modal: {
      title: 'انتباه',
      message: 'املأ الحقول المطلوبة.'
    }
  },
  sexo: {
    masculino: 'ذكر',
    feminino: 'أنثى',
    prefiroNaoDizer: 'أفضل عدم القول'
  },
  entrevistado: {
    email: 'البريد الإلكتروني',
    tipoFormulario: 'استبيان',
    nome: 'الاسم',
    codIdentificacao: 'رمز التعريف',
    iniciaisNome: 'الأحرف الأولى من الاسم',
    idade: 'العمر',
    sexo: 'الجنس',
    estadoCivil: 'الحالة المدنية',
    corPele: 'لون البشرة',
    moradia: 'مع من تعيش',
    profissao: 'احتلال',
    escolaridade: 'التعليم',
    altura: 'الارتفاع',
    peso: 'الوزن',
    IMC: 'IMC',
    cintura: 'الخصر',
    quadril: 'هيب',
    cinturaQuadril: 'نسبة الخصر / الورك',
    cinturaEstatura: 'نسبة الخصر / القامة',
    pressaoArterial: 'ضغط الدم',
    pas: 'ضغط الدم الانقباضي (SBP)',
    pad: 'ضغط الدم الانبساطي (DBP)',
    glicemiaCapilar: 'سكر الدم الشعري',
    espirometria: 'قياس التنفس',
    doencaGinecologica: 'هل تعانين من أي أمراض نسائية؟',
    qualDoencaGinecologica: 'أي مرض نسائي؟',
    doencasReferidas: 'الأمراض المُحالة',
    vacinadoContraCovid: 'هل سبق لك تلقيح ضد Covid-19؟',
    quandoTomouVacina: 'هل تم تطعيمك قبل أو بعد إصابتك بفيروس covid-19؟',
    exposicaoAoCovid: 'ما هي درجة تعرضك لـ Covid؟',
    nivelGravidade: 'ما مدى خطورة كوفيد -19؟',
    tratamentoPrecoce: 'هل تلقيت علاجًا مبكرًا؟',
    sequelas: 'عقابيل؟',
    outraDoencaReferida: 'ما هو المرض؟',
    tomaInsulina: 'هل تستخدم الأنسولين؟',
    covid19: 'العلاج',
    sintomasCovid19: 'الأعراض ',
    tratamentoHospitalar: 'مكان العلاج',
    esforcoAntes: 'اختبار الإجهاد قبل',
    esforcoDepois: 'اختبار الإجهاد بعد',
    ReliaoReferida: 'الدين المشار إليه',
    haQuantosAnos: 'كم سنة مضت؟',
    saudeFisica: 'كيف تنظر إلى صحتك الجسدية؟',
    saudeMental: 'كيف تنظر إلى صحتك العقلية؟',
    QualidadeVida: 'كيف تنظر في نوعية حياتك؟',
    oqueDesejaMelhorar: 'ما العادات التي تود تغييرها؟',
    rendaFamiliar: {
      titulo: 'دخل الأسرة الشهري',
      obs: '* ضع في اعتبارك دخل وثروة جميع أفراد الأسرة'
    },
    vidaSaude: {
      comoConheceu: 'كيف تعرفت على برنامج Vida e Saúde؟',
      quantoTempoAssiste: 'منذ متى وأنت تشاهد برنامج Vida e Saúde؟',
      FrequenciaAssiste: 'كم مرة تشاهد برنامج Vida e Saúde (التلفزيون أو الإنترنت)',
      QualSecaoMaisAtrai: 'أي قسم من برنامج Vida e Saúde يجذب انتباهك أكثر؟'
    }
  },
  stepper: {
    dadosPessoais: 'البيانات الشخصية',
    dadosBiologicos: 'البيانات البيولوجية',
    programaVidaSaude: 'برنامج الحياة والصحة'
  },
  questionario: {
    respostaObrigatoria: 'إجابة مطلوبة',
    RespostasObrigatorias: 'الإجابات المطلوبة',
    preenchaAQuestao: 'املأ السؤال',
    preenchaAsQuestoes: 'املأ الأسئلة',
    escolhaUmaResposta: 'اختر إجابة للمتابعة'
  },
  navegacao: {
    avancar: 'التالي',
    proxima: 'التالي',
    finalizar: 'إنهاء',
    voltar: 'عودة'
  },
  escore: {
    titulo: 'نقاط',
    subtitulo: 'النتيجة التي تم الحصول عليها على أساس الإجابات',
    pontuacao: 'نقاط',
    resultado: 'نتيجة',
    questao: 'سؤال',
    resposta: 'إجابة',
    pontosMelhorar: 'نقاط للتحسين',
    cliqueSaberMais: 'انقر هنا لمعرفة المزيد!',
    pontos: 'نقاط',
    voltarMenu: 'العودة إلى القائمة',
    alertaPopUp: {
      alerta: 'تحذير',
      mensagem: {
        alerta: 'بعد العودة إلى القائمة ، لن يكون من الممكن مشاهدة نقاط للتحسين. هل أنت واثق؟'
      }
    },
    saberMaisPopUp: {
      titulo: 'اغتنم الفرصة لمعرفة المزيد عن Q8RN!'
    }
  },
  classificacoes: {
    insuficiente: 'غير كافٍ',
    regular: 'عادي',
    bom: 'جيد',
    muitoBom: 'جيد جدًا',
    excelente: 'ممتاز',
    indefinido: 'غير محدد'
  },
  sim: 'نعم',
  nao: 'لا',
  notFound: {
    title: 'عفوًا ، 404',
    message: 'الصفحة التي تبحث عنها غير موجودة',
    button: 'أخرجني من هنا'
  },
  selects: {
    simNao: {
      sim: 'نعم',
      nao: 'لا'
    },
    estadoCivil: {
      solteiro: 'مفرد',
      casado: 'متزوج',
      divorciado: 'مطلق،',
      viuvo: 'أرمل',
      outros: 'آخرون'
    },
    corPele: {
      branca: 'أبيض',
      parda: 'بني',
      preta: 'أسود',
      indigena: 'السكان الأصليين',
      amarela: 'أصفر'
    },
    moradia: {
      sozinho: 'وحده',
      paiMae: 'الأب والأم',
      pai: 'الأب',
      mae: 'الأم',
      outros: 'آخرون'
    },
    escolaridade: {
      fundamental: 'أساسي',
      medioIncompleto: 'المدرسة الثانوية',
      graduacao: 'خريج جامعي',
      posGraduacao: 'دراسات عليا',
      mestrado: 'ماجستير',
      doutorado: 'دكتوراة'
    },
    saude: {
      muitoBoa: 'جيد جدًا',
      boa: 'جيد',
      regular: 'عادي',
      ruim: 'سيئة',
      muitoRuim: 'سيء جدًا'
    },
    doencasReferidas: {
      depressao: 'الاكتئاب',
      transtornoAnsiedade: 'اضطراب القلق',
      hipertensao: 'ارتفاع ضغط الدم',
      diabetes: 'مرض السكري',
      obesidade: 'السمنة',
      covid19: 'كوفيد -19',
      outra: 'أخرى',
      nenhuma: 'لا شيء'
    },
    covid19: {
      tratamentoDomiciliar: 'العلاج في المنزل',
      tratamentoHospitalar: 'العلاج في المستشفى'
    },
    sintomasCovid19: {
      comSintomas: 'مع الأعراض',
      semSintomas: 'لا توجد أعراض'
    },
    tratamentoHospitalar: {
      enfermaria: 'مستوصف',
      unidadeSemiIntensiva: 'وحدة شبه مكثفة',
      uti: 'وحدة العناية المركزة'
    },
    vacinadoContraCovid: {
      nao: 'لا',
      umaDose: 'نعم - جرعة واحدة',
      duasDoses: 'نعم - جرعتين.',
      tresDoses: 'نعم - 3 جرعات',
      quatroDoses: 'نعم - 4 جرعات'
    },
    quandoTomouVacina: {
      antes: 'قبل',
      apos: 'بعد'
    },
    exposicaoAoCovid: {
      altissima: 'مرتفع جدًا (مثال: بيئة المستشفى)',
      alta: 'عالية (تكتلات)',
      moderado: 'معتدل (ازدحام قليل)',
      minimo: 'الحد الأدنى (تعرض متقطع)'
    },
    nivelGravidade: {
      gravissimo: 'خطيرة / حرجة (ICU)',
      grave: 'شديد (العلاج في المستشفى)',
      moderado: 'معتدل (رعاية منزلية)',
      leve: 'ضوء'
    },
    rendaFamiliar: {
      PrimeiraFaixa: 'بين BRL 0،00 و BRL 1،908،00',
      segundaFaixa: 'بين BRL 1،908،00 و BRL 2،862،00',
      terceiraFaixa: 'بين BRL 2،862،00 و BRL 5،724،00',
      quartaFaixa: 'بين BRL 5،724،00 و BRL 9،540،00',
      quintaFaixa: 'بين BRL 9،540،00 و BRL 14،310،00',
      sextaFaixa: 'بين BRL 14،310،00 و BRL 23،850،00',
      setimaFaixa: 'أكثر من 23،850،00 ريال برازيلي'
    },
    vidaSaude: {
      comoConheceu: {
        internet: 'الإنترنت',
        tv: 'تلفزيون',
        indicacao: 'إشارة',
        igreja: 'الكنيسة',
        outros: 'آخرون'
      },
      quantoTempoAssiste: {
        zeroSeisMeses: '0-6 أشهر',
        seteOnzeMeses: 'من 7 إلى 11 شهرًا',
        umDoisAnos: 'من 12 إلى 24 شهرًا',
        tresSeisAnos: '25 شهرًا أو أكثر'
      },
      comoAssiste: {
        tv: 'تلفزيون',
        internet: 'الإنترنت',
        tvInternet: 'التلفزيون والإنترنت'
      },
      frequenciaAssiste: {
        umaDuasVezesSemana: 'مرة أو مرتين في الأسبوع',
        tresQuatroVezesSemana: '3 أو 4 مرات في الأسبوع',
        CincoVezesSemana: '5 مرات في الأسبوع أو أكثر'
      },
      qualSecaoMaisAtrai: {
        entrevistaEspecialista: 'مقابلة مع الخبير',
        atividadeFisica: 'النشاط البدني',
        saudePsiquiatra: 'صحة نفسية مع طبيب نفسي',
        receitaSaudavel: 'وصفة صحية'
      }
    }
  },
  ajuda: {
    titulo: 'ساعدني!',
    label: 'أخبرنا بما يحدث',
    enviar: 'أرسل',
    cancelar: 'إلغاء',
    msgSucesso: 'تم طلب المساعدة بنجاح.',
    msgErro: 'خطأ في إرسال المعلومات.'
  },
  termos: {
    titulo: 'شروط الموافقة',
    checkbox: 'أعلن أنني قرأت وقبلت',
    botao: 'متابعة',
    modal: {
      titulo: 'انتباه',
      mensagem: {
        Termo: 'يجب أن توافق على الشروط للرد على الاستطلاع.',
        email: 'من الضروري إبلاغ البريد الإلكتروني للإجابة على الاستبيان'
      }
    },
    baixar: 'شروط التنزيل'
  }
}
