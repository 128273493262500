export default {
  saudacao: 'Bonjour',
  home: {
    toolbar: {
      inicio: 'Démarrer',
      saberMais: 'En savoir plus',
      pesquisas: 'Recherches',
      arquivos: 'Fichiers',
      login: 'Connexion'
    },
    section: {
      questionario: {
        titulo: 'Questionnaire des Huit Remèdes Naturels',
        texto: 'Répondez au questionnaire et vérifiez votre qualité de vie!'
      },
      saberMais: {
        titulo: '8 remèdes naturels',
        texto: 'Voulez-vous en savoir plus sur les huit remèdes naturels?',
        botao: 'Je veux en savoir plus !'
      },
      pesquisas: {
        titulo: 'Recherches',
        texto: 'Nous avons notre propre plate-forme pour mener des recherches.',
        botao: 'Je veux participer à la recherche',
        tenhoUmCodigo: 'J\'ai un code d\'accès'
      },
      arquivos: {
        titulo: 'Fichiers',
        texto: 'Versions imprimables des questionnaires (PDF)'
      }
    },
    footer: {
      grupo: 'Groupe de recherche "Religiosity and Spirituality in Comprehensive Health" (REIS Group)',
      programa: 'Programme de maîtrise professionnelle en promotion de la santé',
      unasp: 'Centre universitaire adventiste de São Paulo - UNASP / SP',
      contact: 'Tél - 2128 6143'
    }
  },
  login: {
    titulo: 'Connexion',
    email: 'E-mail',
    password: 'Mot de passe',
    entrar: 'Connexion',
    logout: 'Déconnexion',
    naoPossuoConta: 'Je n\'ai pas encore de compte',
    emailVerification: {
      titulo: 'E-mail de vérification envoyé!',
      mensagem: 'Veuillez envoyer la confirmation par e-mail.'
    },
    esqueciMinhaSenha: 'J\'ai oublié mon mot de passe'
  },
  esqueciMinhaSenha: {
    titulo: 'J\'ai oublié mon mot de passe',
    email: 'E-mail',
    botao: 'Soumettre',
    emailEnviado: 'E-mail de récupération envoyé',
    emailNaoCadastrado: 'E-mail non enregistré',
    erroEnviarEmail: 'Erreur lors de l\'envoi de l\'e-mail'
  },
  codeLogin: {
    titulo: 'Entrez avec le code',
    email: 'E-mail',
    code: 'Code',
    botao: 'Participer',
    codigoInvalido: 'Code invalide.'
  },
  cadastro: {
    titulo: 'S\'inscrire',
    cadastrar: 'S\'inscrire',
    nome: 'Nom',
    instituicao: 'Institution'
  },
  validacao: {
    invalido: 'Nom d\'utilisateur ou mot de passe invalide',
    emailJaUtilizado: 'E-mail déjà utilisé',
    campoObrigatorio: 'Champ obligatoire',
    emailValido: 'L\'e-mail doit être valide',
    senhaFraca: 'Le mot de passe doit comporter au moins 6 caractères'
  },
  erroInesperado: 'Erreur inattendue',
  snackbar: {
    close: 'Fermer'
  },
  tab: {
    historico: {
      titulo: 'Recherches',
      questionariosRecentes: 'Questionnaires récents',
      questionariosAnteriores: 'Questionnaires précédents',
      score: 'Score',
      naoExistemQuestionarios: 'Il n\'y a pas de questionnaires remplis',
      instituicao: 'Institution',
      minhasPesquisas: 'Mes recherches',
      usuarios: 'Utilisateurs',
      pesquisas: 'Recherches'
    },
    questionario: {
      titulo: 'Questionnaire',
      adulto: 'Adulte',
      adolescente: 'adolescent',
      questionarioSemFormulario: 'Questionnaire sans formulaire',
      realizarQuestionario: 'Effectuer le questionnaire'
    },
    codigos: {
      titulo: 'Codes',
      meusCodigos: 'Mes codes',
      form: {
        code: 'Code',
        descricao: 'Description de votre code'
      },
      criar: 'Créer',
      cancelar: 'Annuler',
      codigoExistente: 'Le code existe déjà',
      naoExistemCodigos: 'Il n\'y a pas de codes enregistrés'
    },
    configuracoes: {
      titulo: 'Paramètres',
      rede: 'Réseau',
      sincronizacao: 'Synchroniser',
      ultimaSincronizacao: 'Dernière synchronisation',
      idioma: 'Langue',
      escolhaIdioma: 'Choisir la langue de l\'application',
      pickLanguage: 'Choisir une langue',
      ajuda: 'Aide',
      relatarProblema: 'Signaler un problème',
      problemaAplicacao: 'Il y a un problème d\'application',
      exportar: 'Exporter des données',
      exportarDados: 'Exporter toutes les recherches effectuées au format CSV',
      listaVazia: 'Aucune donnée à exporter'
    }
  },
  dadosEntrevistado: {
    titulo: 'Données de l\'interviewé'
  },
  formulario: {
    titulo: 'Remplissez le formulaire',
    campoObrigatorio: 'Champ obligatoire',
    modal: {
      titulo: 'Attention',
      mensagem: 'Remplissez les champs obligatoires.'
    }
  },
  sexo: {
    masculino: 'Mâle',
    feminino: 'Femelle',
    prefiroNaoDizer: 'je préfère ne pas dire'
  },
  entrevistado: {
    email: 'E-mail',
    tipoFormulario: 'Questionnaire',
    nome: 'Nom',
    codIdentificacao: 'Code d\'identification',
    iniciaisNome: 'Initiales du nom',
    idade: 'Âge',
    sexo: 'Sexe',
    estadoCivil: 'état civil',
    corPele: 'couleur de peau',
    moradia: 'Avec qui vis-tu',
    profissao: 'Profession',
    escolaridade: 'Éducation',
    altura: 'Hauteur',
    peso: 'Poids',
    imc: 'IMC',
    cintura: 'Taille',
    quadril: 'hanche',
    cinturaQuadril: 'Rapport taille/hanches',
    cinturaEstatura: 'Rapport taille/stature',
    pressaoArterial: 'Tension artérielle',
    pas: 'pression artérielle systolique (PAS)',
    pad: 'pression artérielle diastolique (PAD)',
    glicemiaCapilar: 'glycémie capillaire',
    espirometria: 'spirométrie',
    doencaGinecologica: 'Avez-vous un gynécologuemaladies médicales?',
    qualDoencaGinecologica: `Quelle maladie gynécologique?`,
    doencasReferidas: 'Maladies référées',
    vaccinadoContraCovid: 'Avez-vous déjà été vacciné contre le Covid-19?',
    quandoTomouVacina: 'Avez-vous été vacciné avant ou après avoir eu le covid-19 ?',
    exposicaoAoCovid: 'Quel est votre degré d\'exposition au Covid ?',
    nivelGravidade: 'Quel est le degré de gravité du Covid-19?',
    tratamentoPrecoce: 'Avez-vous eu un traitement précoce?',
    sequelas: 'Séquelles?',
    outraDoencaReferida: 'Qu\'est-ce que la maladie?',
    tomaInsulina: 'Utilisez-vous de l\'insuline?',
    covid19: 'traitement',
    sintomasCovid19: 'Symptômes',
    tratamentoHospitalar: 'Lieu de traitement',
    esforcoAntes: 'Test d\'effort avant',
    esforcoDepois: 'Test d\'effort après',
    religiaoReferida: 'Religion référée',
    haQuantosAnos: 'il y a combien d\'années?',
    saudeFisica: 'Comment considères-tu ta santé physique ?',
    saudeMental: 'Comment considérez-vous votre santé mentale ?',
    qualidadeVida: 'Comment considères-tu ta qualité de vie?',
    oqueDesejaMelhorar: 'Quelles habitudes aimeriez-vous changer ?',
    rendaFamiliar: {
      titulo: 'Revenu familial mensuel',
      obs: '* Compte tenu du revenu et de la richesse de tous les membres de la famille'
    },
    vidaSaude: {
      comoConheceu: 'Comment avez-vous découvert le programme Vida e Saúde ?',
      quantoTempoAssiste: 'Depuis combien de temps regardez-vous l\'émission Vida e Saúde ?',
      frequenciaAssiste: 'À quelle fréquence regardez-vous l\'émission Vida e Saúde (TV ou Internet)',
      qualSecaoMaisAtrai: 'Quelle section du programme Vida e Saúde attire le plus votre attention ?'
    }
  },
  stepper: {
    dadosPessoais: 'Données personnelles',
    dadosBiologicos: 'Données biologiques',
    programaVidaSaude: 'Programa Vida e Saúde'
  },
  questionaire: {
    respostaObrigatoria: 'Réponse requise',
    respostasObrigatorias: 'Réponses requises',
    preenchaAQuestao: 'Remplissez la question',
    preenchaAsQuestoes: 'Remplissez les questions',
    escolhaUmaResposta: 'Choisir une réponse pour continuer'
  },
  navegacao: {
    avancar: 'Suivant',
    proche: 'Suivant',
    finaliser: 'Terminer',
    voltar: 'Retour'
  },
  escore: {
    titulo: 'Score',
    subtitulo: 'Résultat obtenu sur la base des réponses',
    pontuacao: 'Score',
    resultado: 'Résultat',
    questao: 'Question',
    resposta: 'répondre',
    pontosMelhorar: 'Points à améliorer',
    pontos: 'Points',
    voltarMenu: 'Retour au menu',
    alertaPopUp: {
      alerta: 'Attention',
      message: {
        alerta: 'Après être retourné au menu, il ne sera plus possible de voir les points à améliorer. Êtes-vous sûr?'
      }
    }
  },
  classificacoes: {
    insuficiente: 'Insuffisant',
    regular: 'Régulier',
    bom: 'Bien',
    muitoBom: 'Très bien',
    excelente: 'Excellent',
    indefinido: 'Indéfini'
  },
  sim: 'Oui',
  nao: 'Non',
  notFound: {
    title: 'Oups, 404',
    message: 'Une page que vous recherchez n\'existe pas',
    bouton: 'Sortez-moi d\'ici'
  },
  selects: {
    simNao: {
      sim: 'Oui',
      nao: 'Non'
    },
    estadoCivil: {
      solteiro: 'Célibataire',
      casado: 'Marié',
      divorciado: 'divorcé',
      viuvo: 'veuf',
      outros: 'Autres'
    },
    corPele: {
      branca: 'Blanc',
      parda: 'marron',
      preta: 'noir',
      indigena: 'Indigène',
      amarela: 'jaune'
    },
    moradia: {
      sozinho: 'Seul',
      paiMae: 'Père et mère',
      pai: 'Père',
      mae: 'Mère',
      outros: 'Autres'
    },
    escolaridade: {
      fundamental: 'Fondamental',
      medioIncompleto: 'Lycée',
      graduacao: 'Graduação',
      posGraduaçao: 'Postgraduate',
      mestrado: 'Maître',
      doutorado: 'Doctorat'
    },
    saude: {
      muitoBoa: 'Très bien',
      boa: 'Bien',
      régulier: 'Régulier',
      ruim: 'Mauvais',
      muitoRuim: 'Dommage'
    },
    doencasReferidas: {
      depressao: 'dépression',
      transtornoAnsiedade: 'Trouble Anxieux',
      hipertensao: 'Hypertension',
      diabetes: 'Diabète',
      obesidade: 'Obésité',
      covid19: 'Covid-19',
      outra: 'Autre',
      nenhuma: 'Rien'
    },
    covid19: {
      tratamentoDomiciliar: 'Traitement à domicile',
      tratamentoHospitalar: 'Traitement hospitalier'
    },
    sintomasCovid19: {
      comSintomas: 'Avec symptômes',
      semSintomas: 'Aucun symptôme'
    },
    tratamentoHospitalar: {
      enfermaria: 'Infirmerie',
      unidadeSemiIntensiva: 'unité semi-intensive',
      uti: 'USI'
    },
    vacinadoContraCovid: {
      nao: 'Non',
      umaDose: 'Oui - 1 dose',
      duasDoses: 'Oui - 2 doses',
      tresDoses: 'Oui - 3 doses',
      quatroDoses: 'Oui - 4 doses'
    },
    quandoTomouVacina: {
      antes: 'Avant',
      apos: 'Après'
    },
    exposicaoAoCovid: {
      altissima: 'Très élevé (exemple : milieu hospitalier)',
      alta: 'Haut (agglomérations)',
      moderado: 'Modéré (peu de monde)',
      minimo: 'Minimum (exposition sporadique)'
    },
    nivelGravidade: {
      gravissimo: 'Sérieux / Critique (ICU)',
      grave: 'Sévère (traitement hospitalier)',
      moderado: 'Modéré (soins à domicile)',
      leve: 'Lumière'
    },
    rendaFamiliar: {
      primeiraFaixa: 'Entre R$ 0,00 e R$ 1.908,00',
      segundaFaixa: 'Entre R$ 1.908,00 e R$ 2.862,00',
      terceiraFaixa: 'Entre R$ 2.862,00 e R$ 5.724,00',
      quartaFaixa: 'Entre R$ 5.724,00 e R$ 9.540,00',
      quintaFaixa: 'Entre R$ 9.540,00 e R$ 14.310,00',
      sextaFaixa: 'Entre R$ 14.310,00 e R$ 23.850,00',
      setimaFaixa: 'Acima de R$ 23.850,00'
    },
    vidaSaude: {
      comoConheceu: {
        internet: 'Internet',
        tv: 'TV',
        indicacao: 'Indicação',
        igreja: 'Igreja',
        outros: 'Outros'
      },
      quantoTempoAssiste: {
        zeroSeisMeses: '0-6 meses',
        seteOnzeMeses: '7-11 meses',
        umDoisAnos: '12 - 24 meses',
        tresSeisAnos: '25 meses ou mais'
      },
      comoAssiste: {
        tv: 'TV',
        internet: 'Internet',
        tvInternet: 'TV e Internet'
      },
      frequenciaAssiste: {
        umaDuasVezesSemana: '1 ou 2 vezes na semana',
        tresQuatroVezesSemana: '3 ou 4 vezes na semana',
        CincoVezesSemana: '5 vezes na semana ou mais'
      },
      qualSecaoMaisAtrai: {
        entrevistaEspecialista: 'Entrevista com o especialista',
        atividadeFisica: 'Atividade física',
        saudePsiquiatra: 'Saúde mental com psiquiatra',
        receitaSaudavel: 'Receita saudável'
      }
    }
  },
  ajuda: {
    titulo: 'Aidez-moi!',
    label: 'Dites-nous ce qui se passe',
    enviar: 'Envoyer',
    annuler: 'Annuler',
    msgSucesso: 'Aide demandée avec succès.',
    msgErro: 'Erreur lors de l\'envoi des informations.'
  },
  termos: {
    titulo: 'Conditions de consentement',
    checkbox: 'Déclare avoir lu et accepté',
    botao: 'Continuer',
    modal: {
      titulo: 'Attention',
      message: {
        termo: 'Vous devez accepter les termes pour répondre à l\'enquête.',
        email: 'Il est nécessaire de renseigner l\'email pour répondre à l\'enquête.'
      }
    },
    baixar: 'Télécharger les conditions'
  }
}
