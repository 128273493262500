<template>
  <v-snackbar
    v-model="isOn"
    top
  >
    {{ message }}
    <v-btn
      color="pink"
      flat
      @click="isOn = false"
    >
      {{ $t('snackbar.close') }}
    </v-btn>
  </v-snackbar>
</template>
<script>
import SnackbarService from './SnackbarService'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isOn: false,
      message: String
    }
  },
  created () {
    SnackbarService.subscribe(this.id, (event) => {
      this.isOn = event.isOn
      this.message = event.message
    })
  },
  destroyed () {
    SnackbarService.unsubscribe(this.id)
  }
}
</script>
