export default {
  saudacao: 'Olá',
  home: {
    toolbar: {
      inicio: 'Início',
      saberMais: 'Saber mais',
      pesquisas: 'Pesquisas',
      arquivos: 'Arquivos',
      login: 'Login'
    },
    section: {
      questionario: {
        titulo: 'Questionário Oito Remédios Naturais',
        texto: 'Responda o questionário e verifique seu estilo de vida!'
      },
      saberMais: {
        titulo: '8 remédios naturais',
        texto: 'Deseja aprender mais sobre os oito remédios naturais?',
        botao: 'Quero saber mais!'
      },
      pesquisas: {
        titulo: 'Pesquisas',
        texto: 'Temos uma plataforma própria para realização de pesquisas.',
        botao: 'Quero participar de pesquisas',
        tenhoUmCodigo: 'Tenho um código de acesso'
      },
      arquivos: {
        titulo: 'Arquivos',
        texto: 'Versões dos questionários para impressão (PDF)'
      }
    },
    footer: {
      grupo: 'Grupo de Pesquisa “Religiosidade e Espiritualidade na Integralidade da Saúde” (Grupo REIS)',
      programa: 'Programa de Mestrado Profissional em Promoção da Saúde',
      unasp: 'Centro Universitário Adventista de São Paulo – UNASP/SP',
      contato: 'Tel - 2128 6143'
    }
  },
  login: {
    titulo: 'Faça seu Login',
    email: 'E-mail',
    password: 'Senha',
    entrar: 'Entrar',
    logout: 'Sair',
    naoPossuoConta: 'Ainda não possuo conta',
    emailVerificacao: {
      titulo: 'E-mail de verificação enviado!',
      mensagem: 'Favor realizar a confirmação enviada para o seu e-mail.'
    },
    esqueciMinhaSenha: 'Esqueci minha senha'
  },
  esqueciMinhaSenha: {
    titulo: 'Esqueci minha senha',
    email: 'E-mail',
    botao: 'Enviar',
    emailEnviado: 'E-mail de recuperação enviado',
    emailNaoCadastrado: 'E-mail não cadastrado',
    erroEnviarEmail: 'Erro ao enviar o e-mail'
  },
  codeLogin: {
    titulo: 'Entrar com código',
    email: 'E-mail',
    code: 'Código',
    botao: 'Participar',
    codigoInvalido: 'Código inválido.'
  },
  cadastro: {
    titulo: 'Cadastre-se',
    cadastrar: 'Cadastrar',
    nome: 'Nome',
    instituicao: 'Instituição'
  },
  validacao: {
    invalido: 'Usuário e/ou senha inválida',
    emailJaUtilizado: 'E-mail já está sendo utilizado',
    campoObrigatorio: 'Campo Obrigatório',
    emailValido: 'E-mail deve ser válido',
    senhaFraca: 'A senha deve conter no mínimo 6 caracteres'
  },
  erroInesperado: 'Erro inesperado',
  snackbar: {
    close: 'Fechar'
  },
  tab: {
    historico: {
      titulo: 'Pesquisas',
      questionariosRecentes: 'Questionários Recentes',
      questionariosAnteriores: 'Questionários anteriores',
      escore: 'Escore',
      naoExistemQuestionarios: 'Não existem questionários realizados',
      instituicao: 'Instituição',
      minhasPesquisas: 'Minhas pesquisas',
      usuarios: 'Usuários',
      pesquisas: 'Pesquisas'
    },
    questionario: {
      titulo: 'Questionário',
      adulto: 'Adulto',
      adolescente: 'Adolescente',
      questionarioSemFormulario: 'Questionário sem formulário',
      realizarQuestionario: 'Realizar Questionário'
    },
    codigos: {
      titulo: 'Códigos',
      meusCodigos: 'Meus Códigos',
      form: {
        codigo: 'Código',
        descricao: 'Descrição para o seu código'
      },
      criar: 'Criar',
      cancelar: 'Cancelar',
      codigoExistente: 'Código já existente',
      naoExistemCodigos: 'Não existem códigos cadastrados'
    },
    configuracoes: {
      titulo: 'Configurações',
      rede: 'Rede',
      sincronizacao: 'Sincronização',
      ultimaSincronizacao: 'Última sincronização',
      idioma: 'Idioma',
      escolhaIdioma: 'Escolha o idioma da aplicação',
      pickLanguage: 'Selecione um idioma',
      ajuda: 'Ajuda',
      relatarProblema: 'Relatar um problema',
      problemaAplicacao: 'Estou com problemas na aplicação',
      exportar: 'Exportar dados',
      exportarDados: 'Exportar para CSV todas as pesquisas realizadas',
      listaVazia: 'Não há dados a serem exportados'
    }
  },
  dadosEntrevistado: {
    titulo: 'Dados do entrevistado'
  },
  formulario: {
    titulo: 'Preencha o formulário',
    campoObrigatorio: 'Campo obrigatório',
    modal: {
      titulo: 'Atenção',
      mensagem: 'Preencha os campos obrigatórios.'
    }
  },
  sexo: {
    masculino: 'Masculino',
    feminino: 'Feminino',
    prefiroNaoDizer: 'Prefiro não dizer'
  },
  entrevistado: {
    email: 'E-mail',
    telefone: 'Telefone',
    tipoFormulario: 'Questionário',
    nome: 'Nome',
    codIdentificacao: 'Código de identificação',
    iniciaisNome: 'Iniciais do nome',
    idade: 'Idade',
    sexo: 'Sexo',
    estadoCivil: 'Estado civil',
    corPele: 'Cor da pele',
    cidade: 'Cidade',
    estado: 'Estado',
    moradia: 'Com quem mora',
    profissao: 'Profissão',
    escolaridade: 'Escolaridade',
    altura: 'Altura',
    peso: 'Peso',
    imc: 'IMC',
    cintura: 'Cintura',
    quadril: 'Quadril',
    cinturaQuadril: 'Relação cintura/quadril',
    cinturaEstatura: 'Relação cintura/estatura',
    pressaoArterial: 'Pressão arterial',
    pas: 'Pressão arterial sistólica (PAS)',
    pad: 'Pressão arterial diastólica (PAD)',
    glicemiaCapilar: 'Glicemia capilar',
    espirometria: 'Espirometria',
    doencaGinecologica: 'Possui alguma doença ginecológica?',
    qualDoencaGinecologica: `Qual doença ginecológica?`,
    doencasReferidas: 'Doenças referidas',
    vacinadoContraCovid: 'Já foi vacinado contra Covid-19?',
    quandoTomouVacina: 'Foi vacinado antes ou após ter Covid-19?',
    exposicaoAoCovid: 'Qual o seu grau de exposição ao Covid-19?',
    nivelGravidade: 'Qual o nível de gravidade do Covid-19?',
    tratamentoPrecoce: 'Fez tratamento precoce?',
    sequelas: 'Sequelas?',
    outraDoencaReferida: 'Qual é a doença?',
    tomaInsulina: 'Faz uso de insulina?',
    covid19: 'Tratamento',
    sintomasCovid19: 'Sintomas',
    tratamentoHospitalar: 'Local do tratamento',
    esforcoAntes: 'Teste de esforço antes',
    esforcoDepois: 'Teste de esforço depois',
    religiaoReferida: 'Religião referida',
    haQuantosAnos: 'Há quantos anos',
    qualReligiao: 'Você é praticante de alguma religião? Qual?',
    saudeFisica: 'Como considera sua saúde física?',
    saudeMental: 'Como considera sua saúde mental?',
    qualidadeVida: 'Como considera sua qualidade de vida?',
    oqueDesejaMelhorar: 'Que hábitos você gostaria de mudar?',
    rendaFamiliar: {
      titulo: 'Renda familiar mensal',
      obs: '* Considerar o rendimento e patrimônio de todos da família'
    },
    vidaSaude: {
      comoConheceu: 'Como conheceu o programa Vida e Saúde?',
      quantoTempoAssiste: 'Há quanto tempo assiste ao programa Vida e Saúde?',
      frequenciaAssiste: 'Com que frequência você assiste ao programa Vida e Saúde (TV ou internet)',
      comoAssiste: 'Geralmente, como você assiste ao Vida e Saúde?',
      qualSecaoMaisAtrai: 'Qual seção do programa Vida e Saúde mais atrai a sua atenção?',
      qualSecaoMenosAtrai: 'E qual seção menos atrai a sua atenção?',
      sugestoes: 'Que sugestões você daria na programação?'
    }
  },
  stepper: {
    dadosPessoais: 'Dados pessoais',
    dadosBiologicos: 'Dados de saúde',
    programaVidaSaude: 'Programa Vida e Saúde'
  },
  questionario: {
    respostaObrigatoria: 'Resposta obrigatória',
    respostasObrigatorias: 'Respostas obrigatórias',
    preenchaAQuestao: 'Preencha a questão',
    preenchaAsQuestoes: 'Preencha as questões',
    escolhaUmaResposta: 'Escolha uma resposta para continuar'
  },
  navegacao: {
    avancar: 'Avançar',
    proxima: 'Próxima',
    finalizar: 'Finalizar',
    voltar: 'Voltar'
  },
  escore: {
    titulo: 'Escore',
    subtitulo: 'Resultado obtido com base nas respostas',
    pontuacao: 'Pontuação',
    classificacao: 'Classificação',
    resultado: 'Resultado',
    questao: 'Questão',
    resposta: 'Resposta',
    pontosMelhorar: 'Pontos a melhorar',
    cliqueSaberMais: 'Clique aqui para saber mais!',
    pontos: 'Pontos',
    voltarMenu: 'Voltar ao menu',
    alertaPopUp: {
      alerta: 'Alerta',
      mensagem: {
        alerta: 'Após voltar ao menu não será mais possível visualizar os pontos a melhorar. Deseja voltar ao menu?'
      }
    },
    saberMaisPopUp: {
      titulo: 'Aproveite para conhecer mais sobre o Q8RN!'
    }
  },
  classificacoes: {
    insuficiente: 'Insuficiente',
    regular: 'Regular',
    bom: 'Bom',
    muitoBom: 'Muito bom',
    excelente: 'Excelente',
    indefinido: 'Indefinido'
  },
  sim: 'Sim',
  nao: 'Não',
  notFound: {
    title: 'Ooops, 404',
    message: 'A página que está procurando não existe',
    button: 'Me tira daqui!'
  },
  selects: {
    simNao: {
      sim: 'Sim',
      nao: 'Não'
    },
    estadoCivil: {
      solteiro: 'Solteiro',
      casado: 'Casado',
      divorciado: 'Divorciado',
      viuvo: 'Viúvo',
      outros: 'Outros'
    },
    corPele: {
      branca: 'Branca',
      parda: 'Parda',
      preta: 'Preta',
      indigena: 'Indígena',
      amarela: 'Amarela'
    },
    moradia: {
      v1: {
        sozinho: 'Sozinho',
        paiMae: 'Pai e Mãe',
        pai: 'Pai',
        mae: 'Mãe',
        outros: 'Outros'
      },
      v2: {
        sozinho: 'Sozinho',
        paiMae: 'Pai e Mãe',
        pai: 'Pai',
        mae: 'Mãe',
        conjuge: 'Cônjuge',
        filhos: 'Filhos',
        outros: 'Outros'
      }
    },
    escolaridade: {
      fundamental: 'Fundamental',
      medioIncompleto: 'Ensino médio',
      graduacao: 'Graduação',
      posGraduacao: 'Pós-graduação',
      mestrado: 'Mestrado',
      doutorado: 'Doutorado'
    },
    saude: {
      muitoBoa: 'Muito boa',
      boa: 'Boa',
      regular: 'Regular',
      ruim: 'Ruim',
      muitoRuim: 'Muito ruim'
    },
    doencasReferidas: {
      depressao: 'Depressão',
      transtornoAnsiedade: 'Transtorno de Ansiedade',
      hipertensao: 'Hipertensão',
      diabetes: 'Diabetes',
      obesidade: 'Obesidade',
      covid19: 'Covid-19',
      outra: 'Outra',
      nenhuma: 'Nenhuma'
    },
    covid19: {
      tratamentoDomiciliar: 'Tratamento domiciliar',
      tratamentoHospitalar: 'Tratamento Hospitalar'
    },
    sintomasCovid19: {
      comSintomas: 'Com sintomas',
      semSintomas: 'Sem sintomas'
    },
    tratamentoHospitalar: {
      enfermaria: 'Enfermaria',
      unidadeSemiIntensiva: 'Unidade Semi-Intensiva',
      uti: 'UTI'
    },
    vacinadoContraCovid: {
      nao: 'Não',
      umaDose: 'Sim - 1 dose',
      duasDoses: 'Sim - 2 doses',
      tresDoses: 'Sim - 3 doses',
      quatroDoses: 'Sim - 4 doses'
    },
    quandoTomouVacina: {
      antes: 'Antes',
      apos: 'Após'
    },
    exposicaoAoCovid: {
      altissima: 'Altíssimo (exemplo: ambiente hospitalar)',
      alta: 'Alto (aglomerações)',
      moderado: 'Moderado (pouca aglomeração)',
      minimo: 'Mínimo (exposição esporádica)'
    },
    nivelGravidade: {
      gravissimo: 'Gravíssimo / Crítico (UTI)',
      grave: 'Grave (tratamento hospitalar)',
      moderado: 'Moderado (tratamento domiciliar)',
      leve: 'Leve'
    },
    rendaFamiliar: {
      primeiraFaixa: 'Entre R$ 0,00 e R$ 1.908,00',
      segundaFaixa: 'Entre R$ 1.908,00 e R$ 2.862,00',
      terceiraFaixa: 'Entre R$ 2.862,00 e R$ 5.724,00',
      quartaFaixa: 'Entre R$ 5.724,00 e R$ 9.540,00',
      quintaFaixa: 'Entre R$ 9.540,00 e R$ 14.310,00',
      sextaFaixa: 'Entre R$ 14.310,00 e R$ 23.850,00',
      setimaFaixa: 'Acima de R$ 23.850,00'
    },
    vidaSaude: {
      comoConheceu: {
        internet: 'Internet',
        tv: 'TV',
        indicacao: 'Indicação',
        igreja: 'Igreja',
        outros: 'Outros'
      },
      quantoTempoAssiste: {
        zeroSeisMeses: '0-6 meses',
        seteOnzeMeses: '7-11 meses',
        umDoisAnos: '12 - 24 meses',
        tresSeisAnos: '25 meses ou mais'
      },
      comoAssiste: {
        tv: 'TV',
        internet: 'Internet',
        tvInternet: 'TV e Internet'
      },
      frequenciaAssiste: {
        umaDuasVezesSemana: '1 ou 2 vezes na semana',
        tresQuatroVezesSemana: '3 ou 4 vezes na semana',
        CincoVezesSemana: '5 vezes na semana ou mais'
      },
      qualSecaoMaisAtrai: {
        entrevistaEspecialista: 'Entrevista com o especialista',
        atividadeFisica: 'Atividade física',
        saudePsiquiatra: 'Saúde mental com psiquiatra',
        receitaSaudavel: 'Receita saudável'
      }
    }
  },
  ajuda: {
    titulo: 'Me ajude!',
    label: 'Conte-nos o que está havendo',
    enviar: 'Enviar',
    cancelar: 'Cancelar',
    msgSucesso: 'Ajuda solicitada com sucesso.',
    msgErro: 'Erro ao enviar informações.'
  },
  termos: {
    titulo: 'Termos de consentimento',
    checkbox: 'Declaro que li e aceito',
    botao: 'Prosseguir',
    modal: {
      titulo: 'Atenção',
      mensagem: {
        termo: 'Você deve concordar com os termos para responder a pesquisa.',
        email: 'É necessário informar o e-mail para responder a pesquisa.'
      }
    },
    baixar: 'Baixar o termo de consentimento livre e esclarecido'
  }
}
