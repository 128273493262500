export default {
  saudacao: 'Hallo',
  home: {
    toolbar: {
      inicio: 'Start',
      saberMais: 'Erfahren Sie mehr',
      pesquisas: 'Suche',
      arquivos: 'Dateien',
      login: 'Einloggen'
    },
    section: {
      questionario: {
        titulo: 'Fragebogen der acht Naturheilmittel',
        texto: 'Machen Sie das Quiz und prüfen Sie Ihre Lebensqualität!'
      },
      saberMais: {
        titulo: '8 Naturheilmittel',
        texto: 'Möchten Sie mehr über die acht Naturheilmittel erfahren?',
        botao: 'Ich will mehr wissen!'
      },
      pesquisas: {
        titulo: 'Forschung',
        texto: 'Wir haben unsere eigene Plattform, um zu recherchieren.',
        botao: 'Ich möchte an der Forschung teilnehmen',
        tenhoUmCodigo: 'Ich habe einen Zugangscode'
      },
      arquivos: {
        titulo: 'Dateien',
        texto: 'Druckversionen der Fragebögen (PDF)'
      }
    },
    footer: {
      grupo: 'Forschungsgruppe "Religiosität und Spiritualität in umfassender Gesundheit" (REIS-Gruppe)',
      programa: 'Professioneller Masterstudiengang Gesundheitsförderung',
      unasp: 'Adventistisches Universitätszentrum von São Paulo - UNASP/SP',
      contato: 'Tel - 2128 6143'
    }
  },
  login: {
    titulo: 'Verbindung',
    email: 'Email',
    password: 'password',
    entrar: 'Anmelden',
    logout: 'Abmelden',
    naoPossuoConta: 'Ich habe noch kein Konto',
    emailVerificacao: {
      titulo: 'Bestätigungs-E-Mail gesendet!',
      mensagem: 'Bitte E-Mail-Bestätigung.'
    },
    esqueciMinhaSenha: 'Ich habe mein password vergessen'
  },
  esqueciMinhaSenha: {
    titulo: 'Ich habe mein password vergessen',
    email: 'Email',
    botao: 'Senden',
    emailEnviado: 'Wiederherstellungs-E-Mail gesendet',
    emailNaoCadastrado: 'E-Mail nicht registriert',
    erroEnviarEmail: 'Fehler beim Senden der E-Mail'
  },
  codeLogin: {
    titulo: 'Mit dem Code eingeben',
    email: 'Email',
    code: 'Code',
    botao: 'Teilnehmen',
    codigoInvalido: 'Ungültiger Code.'
  },
  cadastro: {
    titulo: 'Registrieren',
    cadastrar: 'Register',
    nome: 'Name',
    instituicao: 'Institution'
  },
  validacao: {
    invalido: 'Benutzername oder password ungültig',
    emailJaUtilizado: 'E-Mail wird bereits verwendet',
    campoObrigatorio: 'Pflichtfeld',
    emailValido: 'E-Mail muss gültig sein',
    senhaFraca: 'password muss mindestens 6 Zeichen lang sein'
  },
  erroInesperado: 'Unerwarteter Fehler',
  snackbar: {
    close: 'Schließen'
  },
  tab: {
    historico: {
      titulo: 'Forschung',
      questionariosRecentes: 'Aktuelle Fragebögen',
      questionariosAnteriores: 'Frühere Fragebögen',
      escore: 'Punktzahl',
      naoExistemQuestionarios: 'Es gibt keine ausgefüllten Fragebögen',
      instituicao: 'Institution',
      minhasPesquisas: 'Meine Forschung',
      usuarios: 'Benutzer',
      pesquisas: 'Suche'
    },
    questionario: {
      titulo: 'Fragebogen',
      adulto: 'Erwachsener',
      adolescente: 'Jugendlicher',
      questionarioSemFormulario: 'Fragebogen ohne Formular',
      realizarQuestionario: 'Fragebogen ausfüllen'
    },
    codigos: {
      titulo: 'Codes',
      meusCodigos: 'Meine Codes',
      form: {
        codigo: 'Code',
        descricao: 'Beschreibung Ihres Codes'
      },
      criar: 'Erstellen',
      cancelar: 'Stornieren',
      codigoExistente: 'Der Code existiert bereits',
      naoExistemCodigos: 'Es gibt keine gespeicherten Codes'
    },
    configuracoes: {
      titulo: 'Einstellungen',
      rede: 'Netzwerk',
      sincronizacao: 'Synchronisieren',
      ultimaSincronizacao: 'Letzte Synchronisierung',
      idioma: 'Sprache',
      escolhaIdioma: 'Anwendungssprache wählen',
      pickLanguage: 'Wähle eine Sprache',
      ajuda: 'Hilfe',
      relatarProblema: 'Problem melden',
      problemaAplicacao: 'Es gibt ein Anwendungsproblem',
      exportar: 'Daten exportieren',
      exportarDados: 'Alle durchgeführten Suchen im CSV-Format exportieren',
      listaVazia: 'Keine Daten zum Exportieren'
    }
  },
  dadosEntrevistado: {
    titulo: 'Daten der Befragten'
  },
  formulario: {
    titulo: 'Formular ausfüllen',
    campoObrigatorio: 'Pflichtfeld',
    modal: {
      titulo: 'Warnung',
      mensagem: 'Füllen Sie die erforderlichen Felder aus.'
    }
  },
  sexo: {
    masculino: 'männlich',
    feminino: 'weiblich',
    prefiroNaoDizer: 'Das sage ich lieber nicht'
  },
  entrevistado: {
    email: 'Email',
    telefone: 'Telefon',
    tipoFormulario: 'Fragebogen',
    nome: 'Name',
    codIdentificacao: 'Identifikationscode',
    iniciaisNome: 'Initialen des Namens',
    idade: 'Alter',
    sexo: 'Geschlecht',
    estadoCivil: 'Zivilstand',
    corPele: 'Hautfarbe',
    cidade: 'Stadt',
    estado: 'Zustand',
    moradia: 'Mit wem lebst du',
    profissao: 'Beruf',
    escolaridade: 'Bildung',
    altura: 'Höhe',
    peso: 'Gewicht',
    imc: 'IMC',
    cintura: 'Taillenumfang',
    quadril: 'hüfte',
    cinturaQuadril: 'Taille/Hüfte-Verhältnis',
    cinturaEstatura: 'Höhe-zu-Statur-Verhältnis',
    pressaoArterial: 'Blutdruck',
    pas: 'systolischer Blutdruck (SBP)',
    pad: 'diastolischer Blutdruck (PAD)',
    glicemiaCapilar: 'Kapillarblutzucker',
    espirometria: 'Spirometrie',
    doencaGinecologica: 'Haben Sie einen Gynäkologen mit medizinischen Krankheiten?',
    qualDoencaGinecologica: 'Welche gynäkologische Erkrankung?',
    doencasReferidas: 'Übertragene Krankheiten',
    vaccinadoContraCovid: 'Wurden Sie jemals gegen Covid-19 geimpft?',
    quandoTomouVacina: 'Wurden Sie vor oder nach Covid-19 geimpft?',
    exposicaoAoCovid: 'Wie stark sind Sie Covid ausgesetzt?',
    nivelGravidade: 'Wie ernst ist Covid-19?',
    tratamentoPrecoce: 'Wurden Sie früh behandelt?',
    sequelas: 'Folgen?',
    outraDoencaReferida: 'Was ist Krankheit?',
    tomaInsulina: 'Verwenden Sie Insulin?',
    covid19: 'Behandlung',
    SintomasCovid19: 'Symptome',
    tratamentoHospitalar: 'Behandlungsort',
    esforcoAntes: 'Stresstest nach vorne',
    esforcoDepois: 'Übungstest nach',
    religiaoReferida: 'Religion bezogen',
    haQuantosAnos: 'vor wie vielen Jahren?',
    qualReligiao: 'Praktizieren Sie irgendeine Religion? Die?',
    saudeFisica: 'Wie schätzen Sie Ihre körperliche Gesundheit ein?',
    saudeMental: 'Wie schätzen Sie Ihre psychische Gesundheit ein?',
    qualidadeVida: 'Wie schätzen Sie Ihre Lebensqualität ein?',
    oqueDesejaMelhorar: 'Welche Gewohnheiten würden Sie gerne ändern?',
    rendaFamiliar: {
      titulo: 'Monatliches Familieneinkommen',
      obs: '*Unter Berücksichtigung des Einkommens und Vermögens aller Familienmitglieder'
    },
    vidaSaude: {
      comoConheceu: 'Wie haben Sie das Programm Vida e Saúde entdeckt?',
      quantoTempoAssiste: 'Wie lange schaust du schon Vida e Saúde?',
      frequenciaAssist: 'Wie oft sehen Sie Vida e Saúde (TV oder Internet)',
      qualSecaoMaisAtrai: 'Welcher Teil des Vida e Saúde-Programms zieht Ihre Aufmerksamkeit am meisten auf sich?'
    }
  },
  stepper: {
    dadosPessoais: 'Persönliche Daten',
    dadosBiologicos: 'Biologische Daten',
    programaVidaSaude: 'Programa Vida e Saude'
  },
  questionario: {
    respostaObrigatoria: 'Antwort erforderlich',
    respostasObrigatorias: 'Antworten erforderlich',
    preenchaAQuestao: 'Frage ausfüllen',
    preenchaAsQuestoes: 'Fragen ausfüllen',
    escolhaUmaResposta: 'Wählen Sie eine Antwort, um fortzufahren'
  },
  navegacao: {
    avancar: 'Weiter',
    proxima: 'Weiter',
    finalizar: 'Fertigstellen',
    voltar: 'Rückkehr'
  },
  escore: {
    titulo: 'Punktzahl',
    subtitulo: 'Aufgrund der Antworten erzieltes Ergebnis',
    pontuacao: 'Ergebnis',
    classificacao: 'Einstufung',
    resultado: 'Ergebnis',
    questao: 'Frage',
    resposta: 'antworten',
    pontosMelhorar: 'Punkte für Verbesserungen',
    cliqueSaberMais: 'Klicken Sie hier, um mehr zu erfahren!',
    pontos: 'Punkte',
    voltarMenu: 'Zurück zum Menü',
    alertaPopUp: {
      alerta: 'Warnung',
      mensagem: {
        alerta: 'Nach der Rückkehr zum Menü können die Verbesserungspunkte nicht mehr angezeigt werden. Bist du dir sicher?'
      }
    },
    saberMaisPopUp: {
      titulo: 'Nutzen Sie die Gelegenheit, um mehr über den Q8RN zu erfahren!'
    }
  },
  classificacoes: {
    insuficiente: 'ungenügend',
    regular: 'Normal',
    bom: 'Gut',
    muitoBom: 'Sehr gut',
    excelente: 'ausgezeichnet',
    indefinido: 'unbestimmt'
  },
  sim: 'Ja',
  nao: 'Nein',
  notFound: {
    title: 'Ups, 404',
    message: 'Eine Seite, die Sie suchen, existiert nicht',
    button: 'Bring mich hier raus'
  },
  selects: {
    simNao: {
      sim: 'Ja',
      nao: 'Nein'
    },
    estadoCivil: {
      solteiro: 'Single',
      casado: 'verheiratet',
      divorciado: 'geschieden',
      viuvo: 'Witwer',
      outros: 'Andere'
    },
    corPele: {
      branca: 'Weiß',
      parda: 'braun',
      preta: 'schwarz',
      indigena: 'einheimisch',
      amarela: 'gelb'
    },
    moradia: {
      v1: {
        sozinho: 'Allein',
        paiMae: 'Vater und Mutter',
        pai: 'Vater',
        mae: 'Mutter',
        outros: 'Andere'
      },
      v2: {
        sozinho: 'Allein',
        paiMae: 'Vater und Mutter',
        pai: 'Vater',
        mae: 'Mutter',
        conjuge: 'Ehepartner',
        filhos: 'Kinder',
        outros: 'Andere'
      }
    },
    escolaridade: {
      fundamental: 'Grundlegend',
      medioIncompleto: 'High School',
      graduacao: 'Absolvent einer Universität',
      posGraduacao: 'Postgraduierter',
      mestrado: 'Meister',
      doutorado: 'Promotion'
    },
    saude: {
      muitoBoa: 'Sehr gut',
      boa: 'Gut',
      regular: 'Normal',
      ruim: 'Schlecht',
      muitoRuim: 'Schade'
    },
    doencasReferidas: {
      depressao: 'Depression',
      transtornoAnsiedade: 'Angststörung',
      hipertensao: 'Bluthochdruck',
      diabetes: 'Diabetes',
      obesidade: 'Fettleibigkeit',
      covid19: 'Covid-19',
      outra: 'Sonstiges',
      nenhuma: 'Nichts'
    },
    covid19: {
      tratamentoDomiciliar: 'Behandlung zu Hause',
      tratamentoHospitalar: 'Krankenhausbehandlung'
    },
    sintomasCovid19: {
      comSintomas: 'Mit Symptomen',
      semSintomas: 'Keine Symptome'
    },
    tratamentoHospitalar: {
      enfermaria: 'Krankenstation',
      unidadeSemiIntensiva: 'halbintensive Einheit',
      uti: 'USI'
    },
    vacinadoContraCovid: {
      nao: 'Nein',
      umaDose: 'Ja - 1 Dosis',
      duasDoses: 'Ja - 2 Dosen',
      tresDoses: 'Ja - 3 Dosen',
      quatroDoses: 'Ja - 4 Dosen'
    },
    quandoTomouVacina: {
      antes: 'vorher',
      apos: 'nach'
    },
    exposicaoAoCovid: {
      altissima: 'Sehr hoch (Beispiel: Krankenhausumgebung)',
      alta: 'Hoch (Siedlungen)',
      moderado: 'Moderat (wenige Leute)',
      minimo: 'Minimum (sporadische Exposition)'
    },
    nivelGravidade: {
      gravissimo: 'Ernst / Kritisch (ICU)',
      grave: 'Schwer (Krankenhausbehandlung)',
      moderado: 'Moderat (Hauspflege)',
      leve: 'Licht'
    },
    rendaFamiliar: {
      primeiraFaixa: 'Zwischen R$ 0,00 und R$ 1.908,00',
      segundaFaixa: 'Zwischen R$ 1.908,00 und R$ 2.862,00',
      terceiraFaixa: 'Zwischen R$ 2.862,00 und R$ 5.724,00',
      quartaFaixa: 'Zwischen R$ 5.724,00 und R$ 9.540,00',
      quintaFaixa: 'Zwischen R$ 9.540,00 und R$ 14.310,00',
      sextaFaixa: 'Zwischen R$ 14.310,00 und R$ 23.850,00',
      setimaFaixa: 'Über von R$ 23.850,00'
    },
    vidaSaude: {
      comoConheceu: {
        internet: 'Web',
        tv: 'Fernsehen',
        indicacao: 'Empfehlung',
        igreja: 'Kirche',
        outros: 'Andere'
      },
      quantoTempoAssiste: {
        zeroSeisMeses: '0-6 Monate',
        seteOnzeMeses: '7-11 Monate',
        umDoisAnos: '12 - 24 Monate',
        tresSeisAnos: '25 Monate oder aber'
      },
      comoAssiste: {
        tv: 'Fernsehen',
        internet: 'Web',
        tvInternet: 'Fernsehen und Internet'
      },
      frequenciaAssiste: {
        umaDuasVezesSemana: '1 oder 2 mal die Woche',
        tresQuatroVezesSemana: '3 oder 4 mal die Woche',
        cincoVezesSemana: '5 Mal pro Woche oder mehr'
      },
      qualSecaoMaisAtrai: {
        entrevistaEspecialista: 'Interview mit dem Experten',
        atividadeFisica: 'Aktividade fisica',
        saudePsiquiatra: 'Psychische Gesundheit mit Psychiater',
        receitaSaudavel: 'Gesundes rezept'
      }
    }
  },
  ajuda: {
    titulo: 'Hilf mir!',
    label: 'Sag uns, was los ist',
    enviar: 'Senden',
    cancelar: 'Abbrechen',
    msgSucesso: 'Hilfe erfolgreich angefordert.',
    msgErro: 'Fehler beim Senden von Informationen.'
  },
  termos: {
    titulo: 'Zustimmungsbedingungen',
    checkbox: 'Erklären gelesen und akzeptiert zu haben',
    botao: 'Weiter',
    modal: {
      titulo: 'Warnung',
      mensagem: {
        termo: 'Sie müssen den Bedingungen zustimmen, um an der Umfrage teilzunehmen.',
        email: 'Es ist notwendig, die E-Mail-Adresse auszufüllen, um die Umfrage zu beantworten.'
      }
    },
    baixar: 'Bedingungen herunterladen'
  }
}
