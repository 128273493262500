import util from './util'
import store from '../store'

const routes = [
  {
    name: 'Home',
    path: util.path.HOME,
    component: () => import('@/views/Home.vue')
  },
  {
    name: 'Login',
    path: util.path.LOGIN,
    component: () => import('@/views/research/Login.vue')
  },
  {
    name: 'CodeLogin',
    path: util.path.research.CODE_LOGIN,
    component: () => import('@/views/research/CodeLogin.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'CodeLoginDirect',
    path: util.path.research.CODE_LOGIN_DIRECT,
    component: () => import('@/views/research/CodeLoginDirect.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'ForgotPassword',
    path: util.path.research.FORGOT_PASSWORD,
    component: () => import('@/views/research/ForgotPassword.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'TermsAndCondition',
    path: util.path.research.TERMS_AND_CONDITION,
    component: () => import('@/views/research/TermsAndCondition.vue'),
    meta: {
      requiresAuth: false
    },
    beforeEnter (to, from, next) {
      if (!from.params.code && !store.state.currentUser.data.code) {
        next(util.path.NOT_FOUND)
      }
      next()
    }
  },
  {
    name: 'SignUp',
    path: util.path.SIGNUP,
    component: () => import('@/views/research/SignUp.vue')
  },
  {
    name: 'Questionnaire',
    path: util.path.QUESTIONNAIRE,
    component: () => import('@/views/Questionnaire.vue'),
    children: [
      { path: util.path.QUESTION_ADULT, component: () => import('@/components/question/Question.vue') },
      { path: util.path.QUESTION_ADOLESCENT, component: () => import('@/components/question/Question.vue') }
    ]
  },
  {
    name: 'ResearchQuestionnaire',
    path: util.path.SCORE,
    component: () => import('@/views/Score.vue'),
    children: [
      { path: util.path.SCORE, component: () => import('@/components/score/Score.vue') }
    ]
  },
  {
    path: util.path.RESEARCH,
    component: () => import('@/views/research/Menu.vue'),
    children: [
      { path: util.path.research.TAB_HISTORY, component: () => import('@/views/research/tabs/History.vue') },
      { path: util.path.research.TAB_QUESTIONNAIRE, component: () => import('@/views/research/tabs/Questionnaire.vue') },
      { path: util.path.research.TAB_CODES, component: () => import('@/views/research/tabs/Codes.vue') },
      { path: util.path.research.TAB_SETTINGS, component: () => import('@/views/research/tabs/Settings.vue') }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Form',
    path: util.path.research.FORM,
    component: () => import('@/views/research/Form.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'UserResearches',
    path: util.path.research.USER_RESEARCHES,
    component: () => import('@/views/research/UserResearches.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: util.path.research.QUESTION_ADULT,
    component: () => import('@/views/research/Questionnaire.vue'),
    children: [
      { path: util.path.research.QUESTION_ADULT, component: () => import('@/components/question/Question.vue') },
      { path: util.path.research.QUESTION_ADOLESCENT, component: () => import('@/components/question/Question.vue') }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ResearchScore',
    path: util.path.research.SCORE,
    component: () => import('@/views/research/Score.vue'),
    children: [
      { path: util.path.research.SCORE, component: () => import('@/components/score/Score.vue') }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ThankYou',
    path: util.path.research.THANK_YOU,
    component: () => import('@/views/research/ThankYou.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: util.path.NOT_FOUND,
    component: () => import('@/views/404.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

export default routes
