import util from './util'
import store from '@/store'

const anonymousUserOpenRoutes = [
  util.path.research.FORM,
  util.path.research.SCORE,
  util.path.research.THANK_YOU
]
const regexQuestionnaire = '/research/questionnaire/.*'

export default (to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (store.getters.isAnonym && (anonymousUserOpenRoutes.includes(to.path) || to.path.match(regexQuestionnaire))) {
      next()
    } else {
      if (store.getters.isAuthenticated) {
        next()
      } else {
        next(util.path.LOGIN)
      }
    }
  }
  next()
}
