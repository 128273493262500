import enUS from './en'
import ptBr from './pt-br'
import es from './es'
import fr from './fr'
import ar from './ar'
import de from './de'

export default {
  'en': enUS,
  'pt-br': ptBr,
  'es': es,
  'fr': fr,
  'ar': ar,
  'de': de
}
