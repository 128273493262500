import router from '@/router/index'
import util from '@/router/util'

export function loadQuestions (context, payload) {
  context.commit('loadQuestions', payload.questions)
  context.commit('updateQuestionnaireType', payload.questionnaireType)
  if (payload.isResearch) {
    router.push(util.path.research.FORM)
  } else {
    router.push(util.path.QUESTIONNAIRE.concat('/').concat(payload.questionnaireType).concat('/1'))
  }
}

export function updatePageNumber (context, payload) {
  context.commit('updatePageNumber', payload.page)
  if (payload.isResearch) {
    router.push(util.path.research.RESEARCH_QUESTIONNAIRE.concat('/').concat(payload.questionnaireType).concat('/').concat(payload.page))
  } else {
    router.push(util.path.QUESTIONNAIRE.concat('/').concat(payload.questionnaireType).concat('/').concat(payload.page))
  }
}

export function updateSelectedOption (context, payload) {
  context.commit('updateSelectedOption', payload)
}

export function fetchUser (context, user) {
  context.commit('setLoggedIn', user !== null)
  if (user) {
    context.commit('setUser', {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      admin: user.admin,
      emailVerified: user.emailVerified,
      isAnonym: user.isAnonymous,
      code: user.code,
      collectEmail: user.collectEmail
    })
  } else {
    context.commit('setUser', null)
  }
}

export function logout (context) {
  context.commit('logout')
}

export function saveInterviewee (context, payload) {
  context.commit('saveInterviewee', payload.interviewee)
}

export function saveEmail (context, payload) {
  context.commit('saveEmail', payload)
}

export function savePhone (context, payload) {
  context.commit('savePhone', payload)
}

export function storeViewHistory (context, payload) {
  context.commit('storeViewHistory', payload)
}

export function saveTotalResearches (context, payload) {
  context.commit('saveTotalResearches', payload)
}

export function clearInterviewee (context) {
  let interviewee = {
    identificationCode: '',
    nameInitials: '',
    age: '',
    gender: '',
    civilStatus: '',
    skinColor: '',
    habitation: '',
    job: '',
    schooling: '',
    height: '',
    weight: '',
    imc: '',
    sbp: '',
    dbp: '',
    referredDiseases: '',
    referredReligion: '',
    howManyYearsAgo: '',
    physicalHealth: '',
    mentalHealth: '',
    lifeQuality: '',
    whatToImprove: ''
  }
  context.commit('saveInterviewee', interviewee)
}
