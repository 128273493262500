export default {
  saudacao: 'Hello',
  home: {
    toolbar: {
      inicio: 'Start',
      saberMais: 'Know more',
      pesquisas: 'Researches',
      arquivos: 'Files',
      login: 'Login'
    },
    section: {
      questionario: {
        titulo: 'Questionnaire of the Eight Natural Remedies',
        texto: 'Answer the questionnaire and check your quality of life!'
      },
      saberMais: {
        titulo: '8 natural remedies',
        texto: 'Do you want to learn more about the eight natural remedies?',
        botao: 'I want to know more!'
      },
      pesquisas: {
        titulo: 'Researches',
        texto: 'We have our own platform for conducting research.',
        botao: 'I want to participate in research',
        tenhoUmCodigo: 'I have an access code'
      },
      arquivos: {
        titulo: 'Files',
        texto: 'Printable versions of the questionnaires (PDF)'
      }
    },
    footer: {
      grupo: 'Research Group “Religiosity and Spirituality in Comprehensive Health” (REIS Group)',
      programa: 'Professional Master\'s Program in Health Promotion',
      unasp: 'Adventist University Center of São Paulo - UNASP / SP',
      contato: 'Tel - 2128 6143'
    }
  },
  login: {
    titulo: 'Login',
    email: 'Email',
    password: 'Password',
    entrar: 'Sign in',
    logout: 'Logout',
    naoPossuoConta: 'I don\'t have an account yet',
    emailVerificacao: {
      titulo: 'Verification email sent!',
      mensagem: 'Please make the confirmation sent to your email.'
    },
    esqueciMinhaSenha: 'I forgot my password'
  },
  esqueciMinhaSenha: {
    titulo: 'I forgot my password',
    email: 'Email',
    botao: 'Submit',
    emailEnviado: 'Recovery email sent',
    emailNaoCadastrado: 'Email not registered',
    erroEnviarEmail: 'Error sending email'
  },
  codeLogin: {
    titulo: 'Enter with code',
    email: 'Email',
    code: 'Code',
    botao: 'Participate',
    codigoInvalido: 'Invalid code.'
  },
  cadastro: {
    titulo: 'Register',
    cadastrar: 'Sign up',
    nome: 'Name',
    instituicao: 'Institution'
  },
  validacao: {
    invalido: 'Invalid username or password',
    emailJaUtilizado: 'E-mail already in use',
    campoObrigatorio: 'Required field',
    emailValido: 'Email must be valid',
    senhaFraca: 'Password should be at least 6 characters'
  },
  erroInesperado: 'Unexpected error',
  snackbar: {
    close: 'Close'
  },
  tab: {
    historico: {
      titulo: 'Researches',
      questionariosRecentes: 'Recent questionnaires',
      questionariosAnteriores: 'Previous questionnaires',
      escore: 'Score',
      naoExistemQuestionarios: 'There are no completed questionnaires',
      instituicao: 'Institution',
      minhasPesquisas: 'My researches',
      usuarios: 'Users',
      pesquisas: 'Researches'
    },
    questionario: {
      titulo: 'Questionnaire',
      adulto: 'Adult',
      adolescente: 'Adolescent',
      questionarioSemFormulario: 'Questionnaire without form',
      realizarQuestionario: 'Perform the questionnaire'
    },
    codigos: {
      titulo: 'Codes',
      meusCodigos: 'My Codes',
      form: {
        codigo: 'Code',
        descricao: 'Description for your code'
      },
      criar: 'Create',
      cancelar: 'Cancel',
      codigoExistente: 'Code already exists',
      naoExistemCodigos: 'There are no codes registered'
    },
    configuracoes: {
      titulo: 'Settings',
      rede: 'Network',
      sincronizacao: 'Sync',
      ultimaSincronizacao: 'Last sync',
      idioma: 'Language',
      escolhaIdioma: 'Choose application language',
      pickLanguage: 'Pick a language',
      ajuda: 'Help',
      relatarProblema: 'Report a problem',
      problemaAplicacao: 'There is an application problem',
      exportar: 'Export data',
      exportarDados: 'Export all researches performed to CSV',
      listaVazia: 'No data to be exported'
    }
  },
  dadosEntrevistado: {
    titulo: 'Interviewee data'
  },
  formulario: {
    titulo: 'Fill out the form',
    campoObrigatorio: 'Required field',
    modal: {
      title: 'Attention',
      message: 'Fill in the required fields.'
    }
  },
  sexo: {
    masculino: 'Male',
    feminino: 'Female',
    prefiroNaoDizer: 'I prefer not to say'
  },
  entrevistado: {
    email: 'Email',
    tipoFormulario: 'Questionnaire',
    nome: 'Name',
    codIdentificacao: 'Identification code',
    iniciaisNome: 'Initials of the name',
    idade: 'Age',
    sexo: 'Gender',
    estadoCivil: 'Civil status',
    corPele: 'Skin color',
    moradia: 'Who do you live with',
    profissao: 'Occupation',
    escolaridade: 'Education',
    altura: 'Height',
    peso: 'Weight',
    imc: 'IMC',
    cintura: 'Waist',
    quadril: 'Hip',
    cinturaQuadril: 'Waist/hip ratio',
    cinturaEstatura: 'Waist/stature ratio',
    pressaoArterial: 'Blood pressure',
    pas: 'Systolic blood pressure (SBP)',
    pad: 'Diastolic blood pressure (DBP)',
    glicemiaCapilar: 'Capillary glycemia',
    espirometria: 'Spirometry',
    doencaGinecologica: 'Do you have any gynecological diseases?',
    qualDoencaGinecologica: `Which gynecological disease?`,
    doencasReferidas: 'Referred diseases',
    vacinadoContraCovid: 'Have you ever been vaccinated against Covid-19?',
    quandoTomouVacina: 'Were you vaccinated before or after you had covid-19?',
    exposicaoAoCovid: 'What is your degree of exposure to Covid?',
    nivelGravidade: 'How serious is Covid-19?',
    tratamentoPrecoce: 'Did you have early treatment?',
    sequelas: 'Sequelae?',
    outraDoencaReferida: 'What is the disease?',
    tomaInsulina: 'Do you use insulin?',
    covid19: 'Treatment',
    sintomasCovid19: 'Symptoms',
    tratamentoHospitalar: 'Place of treatment',
    esforcoAntes: 'Stress test before',
    esforcoDepois: 'Stress test after',
    religiaoReferida: 'Referred Religion',
    haQuantosAnos: 'How many years ago?',
    saudeFisica: 'How do you consider your physical health?',
    saudeMental: 'How do you consider your mental health?',
    qualidadeVida: 'How do you consider your quality of life?',
    oqueDesejaMelhorar: 'What habits would you like to change?',
    rendaFamiliar: {
      titulo: 'Monthly family income',
      obs: '* Consider the income and wealth of all of the family'
    },
    vidaSaude: {
      comoConheceu: 'How did you find out about the Vida e Saúde program?',
      quantoTempoAssiste: 'How long have you been watching the Vida e Saúde program?',
      frequenciaAssiste: 'How often do you watch the Vida e Saúde program (TV or internet)',
      qualSecaoMaisAtrai: 'Which section of the Vida e Saúde program most attracts your attention?'
    }
  },
  stepper: {
    dadosPessoais: 'Personal data',
    dadosBiologicos: 'Biological data',
    programaVidaSaude: 'Programa Vida e Saúde'
  },
  questionario: {
    respostaObrigatoria: 'Required answer',
    respostasObrigatorias: 'Required answers',
    preenchaAQuestao: 'Fill out the question',
    preenchaAsQuestoes: 'Fill out the questions',
    escolhaUmaResposta: 'Choose an answer to continue'
  },
  navegacao: {
    avancar: 'Next',
    proxima: 'Next',
    finalizar: 'Finish',
    voltar: 'Back'
  },
  escore: {
    titulo: 'Score',
    subtitulo: 'Result obtained on the basis of the answers',
    pontuacao: 'Score',
    resultado: 'Result',
    questao: 'Question',
    resposta: 'Answer',
    pontosMelhorar: 'Points to improve',
    pontos: 'Points',
    voltarMenu: 'Back to menu',
    alertaPopUp: {
      alerta: 'Warning',
      mensagem: {
        alerta: 'After going back to menu will no longer be possible to see points to improve. Are you sure?'
      }
    }
  },
  classificacoes: {
    insuficiente: 'Insufficient',
    regular: 'Regular',
    bom: 'Good',
    muitoBom: 'Very good',
    excelente: 'Excellent',
    indefinido: 'Undefined'
  },
  sim: 'Yes',
  nao: 'No',
  notFound: {
    title: 'Whoops, 404',
    message: 'A page you are looking for does not exist',
    button: 'Take me out of here'
  },
  selects: {
    simNao: {
      sim: 'Yes',
      nao: 'No'
    },
    estadoCivil: {
      solteiro: 'Single',
      casado: 'Married',
      divorciado: 'Divorced',
      viuvo: 'Widower',
      outros: 'Others'
    },
    corPele: {
      branca: 'White',
      parda: 'Brown',
      preta: 'Black',
      indigena: 'Indigenous',
      amarela: 'Yellow'
    },
    moradia: {
      sozinho: 'Alone',
      paiMae: 'Father and mother',
      pai: 'Father',
      mae: 'Mother',
      outros: 'Others'
    },
    escolaridade: {
      fundamental: 'Fundamental',
      medioIncompleto: 'High school',
      graduacao: 'Graduação',
      posGraduacao: 'Postgraduate',
      mestrado: 'Master',
      doutorado: 'Doctorate'
    },
    saude: {
      muitoBoa: 'Very good',
      boa: 'Good',
      regular: 'Regular',
      ruim: 'Bad',
      muitoRuim: 'Too bad'
    },
    doencasReferidas: {
      depressao: 'Depression',
      transtornoAnsiedade: 'Anxiety Disorder',
      hipertensao: 'Hypertension',
      diabetes: 'Diabetes',
      obesidade: 'Obesity',
      covid19: 'Covid-19',
      outra: 'Other',
      nenhuma: 'None'
    },
    covid19: {
      tratamentoDomiciliar: 'Home treatment',
      tratamentoHospitalar: 'Hospital treatment'
    },
    sintomasCovid19: {
      comSintomas: 'With symptoms',
      semSintomas: 'No symptoms'
    },
    tratamentoHospitalar: {
      enfermaria: 'Infirmary',
      unidadeSemiIntensiva: 'Semi-Intensive Unit',
      uti: 'ICU'
    },
    vacinadoContraCovid: {
      nao: 'No',
      umaDose: 'Yes - 1 dose',
      duasDoses: 'Yes - 2 doses',
      tresDoses: 'Yes - 3 doses',
      quatroDoses: 'Yes - 4 doses'
    },
    quandoTomouVacina: {
      antes: 'Before',
      apos: 'After'
    },
    exposicaoAoCovid: {
      altissima: 'Very high (example: hospital environment)',
      alta: 'High (agglomerations)',
      moderado: 'Moderate (little crowding)',
      minimo: 'Minimum (sporadic exposure)'
    },
    nivelGravidade: {
      gravissimo: 'Serious / Critical (ICU)',
      grave: 'Severe (hospital treatment)',
      moderado: 'Moderate (home care)',
      leve: 'Light'
    },
    rendaFamiliar: {
      primeiraFaixa: 'Entre R$ 0,00 e R$ 1.908,00',
      segundaFaixa: 'Entre R$ 1.908,00 e R$ 2.862,00',
      terceiraFaixa: 'Entre R$ 2.862,00 e R$ 5.724,00',
      quartaFaixa: 'Entre R$ 5.724,00 e R$ 9.540,00',
      quintaFaixa: 'Entre R$ 9.540,00 e R$ 14.310,00',
      sextaFaixa: 'Entre R$ 14.310,00 e R$ 23.850,00',
      setimaFaixa: 'Acima de R$ 23.850,00'
    },
    vidaSaude: {
      comoConheceu: {
        internet: 'Internet',
        tv: 'TV',
        indicacao: 'Indicação',
        igreja: 'Igreja',
        outros: 'Outros'
      },
      quantoTempoAssiste: {
        zeroSeisMeses: '0-6 meses',
        seteOnzeMeses: '7-11 meses',
        umDoisAnos: '12 - 24 meses',
        tresSeisAnos: '25 meses ou mais'
      },
      comoAssiste: {
        tv: 'TV',
        internet: 'Internet',
        tvInternet: 'TV e Internet'
      },
      frequenciaAssiste: {
        umaDuasVezesSemana: '1 ou 2 vezes na semana',
        tresQuatroVezesSemana: '3 ou 4 vezes na semana',
        CincoVezesSemana: '5 vezes na semana ou mais'
      },
      qualSecaoMaisAtrai: {
        entrevistaEspecialista: 'Entrevista com o especialista',
        atividadeFisica: 'Atividade física',
        saudePsiquiatra: 'Saúde mental com psiquiatra',
        receitaSaudavel: 'Receita saudável'
      }
    }
  },
  ajuda: {
    titulo: 'Help me!',
    label: 'Tell us what\'s going on',
    enviar: 'Send',
    cancelar: 'Cancel',
    msgSucesso: 'Help requested successfully.',
    msgErro: 'Error sending information.'
  },
  termos: {
    titulo: 'Terms of consent',
    checkbox: 'Declare that I have read and accepted',
    botao: 'Proceed',
    modal: {
      titulo: 'Attention',
      mensagem: {
        termo: 'You must agree to the terms to respond to the survey.',
        email: 'It is necessary to inform the email to answer the survey.'
      }
    },
    baixar: 'Download terms'
  }
}
