export function loadQuestions (state, questions) {
  state.questions = questions
}

export function updateSelectedOption (state, payload) {
  state.questions.filter(question => question.numero === payload.number)[0].opcaoSelecionada = payload.newOption
}

export function updateQuestionnaireType (state, questionnaireType) {
  state.questionnaireType = questionnaireType
}

export function updatePageNumber (state, page) {
  state.page = page
}

export function setLoggedIn (state, loggedIn) {
  state.currentUser.loggedIn = loggedIn
}

export function setUser (state, user) {
  if (!user.code) {
    user.code = state.currentUser.data.code
    user.collectEmail = state.currentUser.data.collectEmail
  }
  state.currentUser.data = user
}

export function logout (state) {
  state.currentUser.loggedIn = false
}

export function saveInterviewee (state, interviewee) {
  state.interviewee = interviewee
}

export function saveEmail (state, email) {
  state.email = email
}

export function savePhone (state, phone) {
  state.phone = phone
}

export function storeViewHistory (state, payload) {
  state.currentUser.history.researches = payload
}

export function saveTotalResearches (state, payload) {
  state.currentUser.history.totalResearches = payload
}
