export default {
  saudacao: 'Hola',
  home: {
    toolbar: {
      inicio: 'Inicio',
      saberMais: 'Saber más',
      pesquisas: 'Investigación',
      arquivos: 'Archivos',
      login: 'Iniciar sesión'
    },
    section: {
      questionario: {
        titulo: 'Cuestionario de los Ocho Remedios Naturales',
        texto: '¡Responde el cuestionario y verifica tu calidad de vida!'
      },
      saberMais: {
        titulo: '8 remedios naturales',
        texto: '¿Quieres aprender más sobre los ocho remedios naturales?',
        botao: '¡Quiero saber más!'
      },
      pesquisas: {
        titulo: 'Investigación',
        texto: 'Tenemos nuestra propia plataforma para realizar investigaciones',
        botao: 'Quiero participar en investigaciones',
        tenhoUmCodigo: 'Tengo un código de acceso'
      },
      arquivos: {
        titulo: 'Archivos',
        texto: 'Versiones para imprimir de los cuestionarios (PDF)'
      }
    },
    footer: {
      grupo: 'Grupo de investigación" Religiosidad y espiritualidad en salud integral "(Grupo REIS)',
      programa: 'Programa de maestría profesional en promoción de la salud',
      unasp: 'Centro Universitario Adventista de São Paulo - UNASP / SP',
      contato: 'Tel - 2128 6143'
    }
  },
  login: {
    titulo: 'Iniciar sesión',
    email: 'Correo electrónico',
    password: 'Contraseña',
    entrar: 'Iniciar',
    logout: 'Cerrar sesión',
    naoPossuoConta: 'Todavía no tengo una cuenta',
    emailVerificacao: {
      titulo: '¡El mensaje de verificación ha sido enviado!',
      mensagem: 'Por favor, haga la confirmación enviada a su correo electrónico.'
    },
    esqueciMinhaSenha: 'Olvidé mi contraseña'
  },
  esqueciMinhaSenha: {
    titulo: 'Olvidé mi contraseña',
    email: 'Correo electrónico',
    botao: 'Enviar',
    emailEnviado: 'Correo electrónico de recuperación enviado',
    emailNaoCadastrado: 'Correo electrónico no registrado',
    erroEnviarEmail: 'Error al enviar correo electrónico'
  },
  codeLogin: {
    titulo: 'Entrar con código',
    email: 'Correo electrónico',
    code: 'Código',
    botao: 'Participar',
    codigoInvalido: 'Código inválido'
  },
  cadastro: {
    titulo: 'Registrarse',
    cadastrar: 'Registrar',
    nome: 'Nombre',
    instituicao: 'Institución'
  },
  validacao: {
    invalido: 'Usuario o contraseña invalido',
    emailJaUtilizado: 'Correo electronico ya fue usado',
    campoObrigatorio: 'Campo obligatorio',
    emailValido: 'El email debe ser válido',
    senhaFraca: 'La contraseña debe tener al menos 6 caracteres.'
  },
  erroInesperado: 'Erro inesperado',
  snackbar: {
    close: 'Cerrar'
  },
  tab: {
    historico: {
      titulo: 'Investigaciones',
      questionariosRecentes: 'Cuestionarios recientes',
      questionariosAnteriores: 'Cuestionarios anteriores',
      escore: 'Puntuación',
      naoExistemQuestionarios: 'No existen cuestionarios realizados',
      instituicao: 'Institución',
      minhasPesquisas: 'Mis investigaciones',
      usuarios: 'los usuarios',
      pesquisas: 'Investigaciones'
    },
    questionario: {
      titulo: 'Cuestionario',
      adulto: 'Adulto',
      adolescente: 'Adolescente',
      questionarioSemFormulario: 'Cuestionario sin formulario',
      realizarQuestionario: 'Realizar el cuestionario'
    },
    codigos: {
      titulo: 'Códigos',
      meusCodigos: 'Mis códigos',
      form: {
        codigo: 'Código',
        descricao: 'Descripción de su código'
      },
      criar: 'Crear',
      cancelar: 'Cancelar',
      codigoExistente: 'El código ya existe',
      naoExistemCodigos: 'No hay códigos registrados'
    },
    configuracoes: {
      titulo: 'Ajustes',
      rede: 'Red',
      sincronizacao: 'Sincronizar',
      ultimaSincronizacao: 'Última sincronización',
      idioma: 'Idioma',
      escolhaIdioma: 'Elija el idioma de la aplicación',
      pickLanguage: 'Elige un idioma',
      ajuda: 'Ayuda',
      relatarProblema: 'Reportar un problema',
      problemaAplicacao: 'Hay un problema de aplicación',
      exportar: 'Exportar datos',
      exportarDados: 'Exportar todas las búsquedas realizadas a CSV',
      listaVazia: 'No hay datos para exportar'
    }
  },
  dadosEntrevistado: {
    titulo: 'Datos del entrevistado'
  },
  formulario: {
    titulo: 'Rellenar el formulario',
    campoObrigatorio: 'Campo obligatorio',
    modal: {
      title: 'Atención',
      message: 'Complete los campos obligatorios.'
    }
  },
  entrevistado: {
    email: 'Email',
    tipoFormulario: 'Cuestionario',
    nome: 'Nombre',
    codIdentificacao: 'Código de identificación',
    iniciaisNome: 'Iniciales del nombre',
    idade: 'Años',
    sexo: 'Género',
    estadoCivil: 'Estado civil',
    corPele: 'Color de piel',
    moradia: 'Con quien vives',
    profissao: 'Ocupación',
    escolaridade: 'Educación',
    altura: 'Altura',
    peso: 'Peso',
    imc: 'IMC',
    cintura: 'Cintura',
    quadril: 'Cadera',
    cinturaQuadril: 'Proporción cintura/cadera',
    cinturaEstatura: 'Relación cintura/estatura',
    pressaoArterial: 'Presión arterial',
    pas: 'Presión sanguínea sistólica (PSS)',
    pad: 'Presión arterial diastólica (PAD)',
    glicemiaCapilar: 'Glucemia capilar',
    espirometria: 'Espirometria',
    doencaGinecologica: 'Possui alguma doença ginecológica?',
    qualDoencaGinecologica: `¿Qué enfermedad ginecológica?`,
    doencasReferidas: 'Enfermedades referidas',
    vacinadoContraCovid: '¿Alguna vez ha sido vacunado contra Covid-19?',
    quandoTomouVacina: '¿Se vacunó antes o después de tener covid-19?',
    exposicaoAoCovid: '¿Cuál es su grado de exposición a Covid?',
    nivelGravidade: '¿Qué tan grave es el Covid-19?',
    tratamentoPrecoce: '¿Recibiste tratamiento temprano?',
    sequelas: '¿Secuelas?',
    outraDoencaReferida: '¿Cual es la enfermedad?',
    tomaInsulina: '¿Usa insulina?',
    covid19: 'Tratamiento',
    sintomasCovid19: 'Síntomas',
    tratamentoHospitalar: 'Lugar de tratamiento',
    esforcoAntes: 'Prueba de estrés antes',
    esforcoDepois: 'Prueba de estres despues',
    religiaoReferida: 'Religión referida',
    haQuantosAnos: '¿Hace cuántos años?',
    saudeFisica: '¿Cómo consideras tu salud física?',
    saudeMental: '¿Cómo consideras tu salud mental?',
    qualidadeVida: '¿Cómo considera su calidad de vida?',
    oqueDesejaMelhorar: '¿Qué hábitos te gustaría cambiar?',
    rendaFamiliar: {
      titulo: 'Ingreso familiar mensual',
      obs: '* Considerando los ingresos y la riqueza de todos en la familia'
    },
    vidaSaude: {
      comoConheceu: '¿Cómo se enteró del programa Vida e Saúde?',
      quantoTempoAssiste: '¿Cuánto tiempo hace que ve el programa Vida e Saúde?',
      frequenciaAssiste: '¿Con qué frecuencia ve el programa Vida e Saúde (TV o internet)',
      qualSecaoMaisAtrai: '¿Qué sección del programa Vida e Saúde llama más su atención?'
    }
  },
  stepper: {
    dadosPessoais: 'Datos personales',
    dadosBiologicos: 'Datos biológicos',
    programaVidaSaude: 'Programa Vida e Saúde'
  },
  questionario: {
    respostaObrigatoria: 'Respuesta obligatoria',
    respostasObrigatorias: 'Respuestas obligatorias',
    preenchaAQuestao: 'Rellene la cuestión',
    preenchaAsQuestoes: 'Rellene las cuestiones',
    escolhaUmaResposta: 'Seleccione una respuesta'
  },
  navegacao: {
    avancar: 'Avanzar',
    proxima: 'Siguiente',
    finalizar: 'Final',
    voltar: 'Regreso'
  },
  sexo: {
    masculino: 'Hombre',
    feminino: 'Hembra',
    prefiroNaoDizer: 'Prefiero no decir'
  },
  escore: {
    titulo: 'Puntuación',
    subtitulo: 'Resultado obtenido en base a las respuestas.',
    pontuacao: 'Escore',
    resultado: 'Resultado',
    questao: 'Cuestión',
    resposta: 'Respuesta',
    pontosMelhorar: 'Puntos a mejorar',
    pontos: 'Puntos',
    voltarMenu: 'Volver al menú',
    alertaPopUp: {
      alerta: 'Alerta',
      mensagem: {
        alerta: 'Después de volver al menú ya no será posible visualizar los puntos a mejorar. ¿Desea volver al menú?'
      }
    }
  },
  classificacoes: {
    insuficiente: 'Insuficiente',
    regular: 'Regular',
    bom: 'Bueno',
    muitoBom: 'Muy bueno',
    excelente: 'Excelente',
    indefinido: 'Indefinido'
  },
  sim: 'Si',
  nao: 'No',
  notFound: {
    title: 'Uy, 404',
    message: 'La página que estás buscando no existe',
    button: 'Sácame de aquí'
  },
  selects: {
    simNao: {
      sim: 'Si',
      nao: 'No'
    },
    estadoCivil: {
      solteiro: 'Soltero',
      casado: 'Casado',
      divorciado: 'Divorciada',
      viuvo: 'Viudo',
      outros: 'Otros'
    },
    corPele: {
      branca: 'Blanco',
      parda: 'Marrón',
      preta: 'Negro',
      indigena: 'Indigena',
      amarela: 'Amarillo'
    },
    moradia: {
      sozinho: 'Solo',
      paiMae: 'Papa y mama',
      pai: 'Papa',
      mae: 'Mama',
      outros: 'Otros'
    },
    escolaridade: {
      fundamental: 'Fundamental',
      medioIncompleto: 'Escuela secundaria',
      graduacao: 'Graduación',
      posGraduacao: 'Postgrado',
      mestrado: 'Maestro',
      doutorado: 'Doctorado'
    },
    saude: {
      muitoBoa: 'Muy buena',
      boa: 'Buena',
      regular: 'Regular',
      ruim: 'Mal',
      muitoRuim: 'Muy malo'
    },
    doencasReferidas: {
      depressao: 'Depresión',
      transtornoAnsiedade: 'Trastorno de ansiedad',
      hipertensao: 'Hipertensión',
      diabetes: 'Diabetes',
      obesidade: 'Obesidad',
      covid19: 'Covid-19',
      outra: 'Otro',
      nenhuma: 'Ninguna'
    },
    covid19: {
      tratamentoDomiciliar: 'Tratamiento en el hogar',
      tratamentoHospitalar: 'Tratamiento hospitalario'
    },
    sintomasCovid19: {
      comSintomas: 'Con síntomas',
      semSintomas: 'Sin síntomas'
    },
    tratamentoHospitalar: {
      enfermaria: 'Enfermería',
      unidadeSemiIntensiva: 'Unidad semi-intensiva',
      uti: 'UCI'
    },
    vacinadoContraCovid: {
      nao: 'No',
      umaDose: 'Sí - 1 dosis',
      duasDoses: 'Sí - 2 dosis',
      tresDoses: 'Sí - 3 dosis',
      quatroDoses: 'Sí - 4 dosis'
    },
    quandoTomouVacina: {
      antes: 'Antes',
      apos: 'Después'
    },
    exposicaoAoCovid: {
      altissima: 'Muy alto (ejemplo: entorno hospitalario)',
      alta: 'Alto (aglomeraciones)',
      moderado: 'Moderado (poco hacinamiento)',
      minimo: 'Mínimo (exposición esporádica)'
    },
    nivelGravidade: {
      gravissimo: 'Serio / Crítico (UCI)',
      grave: 'Severo (tratamiento hospitalario)',
      moderado: 'Moderado (atención domiciliaria)',
      leve: 'Luz'
    },
    rendaFamiliar: {
      primeiraFaixa: 'Entre R$ 0,00 e R$ 1.908,00',
      segundaFaixa: 'Entre R$ 1.908,00 e R$ 2.862,00',
      terceiraFaixa: 'Entre R$ 2.862,00 e R$ 5.724,00',
      quartaFaixa: 'Entre R$ 5.724,00 e R$ 9.540,00',
      quintaFaixa: 'Entre R$ 9.540,00 e R$ 14.310,00',
      sextaFaixa: 'Entre R$ 14.310,00 e R$ 23.850,00',
      setimaFaixa: 'Acima de R$ 23.850,00'
    },
    vidaSaude: {
      comoConheceu: {
        internet: 'Internet',
        tv: 'TV',
        indicacao: 'Indicação',
        igreja: 'Igreja',
        outros: 'Outros'
      },
      quantoTempoAssiste: {
        zeroSeisMeses: '0-6 meses',
        seteOnzeMeses: '7-11 meses',
        umDoisAnos: '12 - 24 meses',
        tresSeisAnos: '25 meses ou mais'
      },
      comoAssiste: {
        tv: 'TV',
        internet: 'Internet',
        tvInternet: 'TV e Internet'
      },
      frequenciaAssiste: {
        umaDuasVezesSemana: '1 ou 2 vezes na semana',
        tresQuatroVezesSemana: '3 ou 4 vezes na semana',
        CincoVezesSemana: '5 vezes na semana ou mais'
      },
      qualSecaoMaisAtrai: {
        entrevistaEspecialista: 'Entrevista com o especialista',
        atividadeFisica: 'Atividade física',
        saudePsiquiatra: 'Saúde mental com psiquiatra',
        receitaSaudavel: 'Receita saudável'
      }
    }
  },
  ajuda: {
    titulo: '¡Ayúdame!',
    label: 'Cuéntanos qué está pasando',
    enviar: 'Enviar',
    cancelar: 'Cancelar',
    msgSucesso: 'Ayuda solicitada con éxito',
    msgErro: 'Error al enviar información'
  },
  termos: {
    titulo: 'Condiciones de consentimiento',
    checkbox: 'Declaro que he leído y aceptado',
    botao: 'Continuar',
    modal: {
      titulo: 'Atención',
      mensagem: {
        termo: 'Debe aceptar los términos para responder a la encuesta.',
        email: 'Es necesario informar al email para responder a la encuesta.'
      }
    },
    baixar: 'Término de descarga'
  }
}
