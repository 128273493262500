import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './messages'

Vue.use(VueI18n)

function loadLocaleMessages () {
  return messages
}

export default new VueI18n({
  locale: navigator.language.toLowerCase(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt-br',
  messages: loadLocaleMessages()
})
