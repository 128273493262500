<template>
  <v-app style="background: #FFF">
    <v-content>
      <router-view />
    </v-content>
    <Snackbar id="Snackbar" />
    <Dialog id="DefaultDialog" />
  </v-app>
</template>

<script>
import Dialog from './components/dialogs/generic/Dialog'
import Snackbar from './components/snackbar/Snackbar'

export default {
  name: 'App',
  components: {
    Snackbar,
    Dialog
  }
}
</script>
