import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './plugins/vuetify'
import router from './router/index'
import store from './store/index'
import i18n from './locale/i18n'
import './registerServiceWorker'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/analytics'

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: 'AIzaSyCebqKVMvCuHZoEzCUyoDce87kEIFPLvLE',
  authDomain: 'q8rn-pwa.firebaseapp.com',
  databaseURL: 'https://q8rn-pwa.firebaseio.com',
  projectId: 'q8rn-pwa',
  storageBucket: '',
  messagingSenderId: '953358228097',
  appId: '1:953358228097:web:475c1f9c273fb17a'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

firebase.auth().onAuthStateChanged(user => {
  if (user && user.isAnonymous) {
    store.dispatch('fetchUser', user)
  } else if (user) {
    firebase.database().ref('users/' + user.uid + '/admin')
      .once('value')
      .then(snap => {
        user.admin = snap.val()
        store.dispatch('fetchUser', user)
      })
      .catch(error => {
        console.debug(error)
      })
  } else {
    store.dispatch('fetchUser', user)
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
