import Vue from 'vue'

export default class DialogService {
  static _eventBus = new Vue()

  static subscribe (id, callBack) {
    return DialogService._eventBus.$on(id, callBack)
  }

  static unsubscribe (id) {
    return DialogService._eventBus.$off(id)
  }

  static on (id, title, message) {
    DialogService._eventBus.$emit(id, { isOn: true, title, message })
  }

  static off (id) {
    DialogService._eventBus.$emit(id, { isOn: false })
  }
}
