<template>
  <div :id="id" class="text-xs-center">
    <v-dialog
      v-model="isOn"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            data-cy="alert-ok-button"
            color="primary"
            flat
            @click="isOn = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DialogService from './DialogService'

export default {
  name: 'Dialog',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      isOn: false,
      title: String,
      message: String
    }
  },
  created () {
    DialogService.subscribe(this.id, (event) => {
      this.isOn = event.isOn
      this.title = event.title
      this.message = event.message
    })
  },
  destroyed () {
    DialogService.unsubscribe(this.id)
  }
}
</script>
