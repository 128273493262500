import Vue from 'vue'

export default class SnackbarService {
  static _eventBus = new Vue()

  static subscribe (id, callBack) {
    return SnackbarService._eventBus.$on(id, callBack)
  }

  static unsubscribe (id) {
    return SnackbarService._eventBus.$off(id)
  }

  static on (id, message) {
    SnackbarService._eventBus.$emit(id, { isOn: true, message })
  }

  static off (id) {
    SnackbarService._eventBus.$emit(id, { isOn: false })
  }
}
